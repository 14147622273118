@media(min-width: 375px)
{
    .dnd-upload-status
    {
        .dnd-upload-details
        {
            .name
            {
                padding-right: 60px;
            }
        }
    }
}

@media(min-width: 768px)
{
    .dnd-upload-status
    {
        .dnd-upload-details
        {
            font-size: 18px;
        }
    }
}