#Wrapper
{
    #Content
    {
        .youtube-player
        {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;
            margin-top: 50px;
            margin-bottom: 50px;

            iframe
            {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
                background: transparent;
            }

            img {
                object-fit: cover;
                display: block;
                left: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                width: 100%;
                position: absolute;
                right: 0;
                top: 0;
                border: none;
                height: auto;
                cursor: pointer;
                transition: 0.4s all;
                height: 99%;

                &:hover
                {
                    -webkit-filter: brightness(75%);
                }
            }

            .play
            {
                height: 72px;
                width: 72px;
                left: 50%;
                top: 50%;
                margin-left: -36px;
                margin-top: -36px;
                position: absolute;
                background: url('images/play.png') no-repeat;
                cursor: pointer;
                transform: scale(0.75);
            }
        }
    }
}
