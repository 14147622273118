@media(min-width: 768px)
{
    #Wrapper
    {
        #Content
        {
            .youtube-player
            {
                margin: 100px 0;

                .play
                {
                    transform: scale(1);
                }
            }


        }
    }
}
