/* filter categories */

.news-grid .post-filter ul.vc_grid-filter
{
    padding: 10px 0 30px 0;
    margin: 0;
    top: -10px;
    position: relative;
}

.post-filter  ul.vc_grid-filter
{
    &:before
    {
        content: 'Filtern nach';
        display: inline-block;
        position: relative;
        margin-right: 10px;
        top: -21px;
    }
}

.vc_grid-container-wrapper .post-filter .vc_grid-filter.vc_grid-filter-color-grey .vc_grid-filter-item.vc_active,
.vc_grid-container-wrapper .post-filter .vc_grid-filter.vc_grid-filter-color-grey .vc_grid-filter-item:hover
{
    background: none;
}

.post-filter  ul.vc_grid-filter
{
    padding-bottom: 30px;

    li.vc_grid-filter-item
    {
        margin: 0;
        padding: 0 10px;
        opacity: 0.5;

    }

    li.vc_active{
        opacity: 1.0;
    }

    li.vc_grid-filter-item [data-vc-grid-filter-value="*"]
    {
        width: 160px;
        font-size: 15px;
        font-family: 'HouschkaRoundedAlt-Medium';
        color: $color-grey-dark;
        line-height: 43px;
        text-transform: uppercase;
        background-color: none;
        border: 2px solid $color-grey-dark;
        border-radius: 10px;
        display: inline-block;
        padding: 0;
    }

    li.vc_grid-filter-item > [data-vc-grid-filter-value="*"]
    {
        position: relative;
        top: -21px;
    }

    li.vc_grid-filter-item
    {
        span:not([data-vc-grid-filter-value="*"])
        {
            position: relative;
            font-size: 11px;
            font-family: 'HouschkaRoundedAlt-Medium'; /* HouschkaRoundedAlt-Bold */
            font-weight: bold;
            color: $color-grey-dark;
            text-transform: uppercase;
            width: 100%;
            display: block;
        }
    }
}

.post-filter ul.vc_grid-filter li.vc_grid-filter-item{
    &:nth-child(1n+6){
        display: none;
    }
}

.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-35"]:before,
.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-17"]:before,
.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-775"]:before,
.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-14"]:before,
.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-19"]:before,
.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-36"]:before,
.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-18"]:before,
.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-1084"]:before
{
    content: '';
    position: relative;
    margin: 0 auto;
    display: block;
    width: 47px;
    height: 47px;
    margin-bottom: -4px;
}

.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-35"]:before
{
    background: url('/wp-content/uploads/2017/03/LuC_icons_v2_Deko.svg') no-repeat;
    background-size: contain;
}

.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-17"]:before
{
    content: "\f09a";
    font-family: "Font Awesome 5 Brands";
    font-size: 47px;
    top: 8px;
}

.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-775"]:before
{
    background: url('/wp-content/uploads/2017/03/LuC_icons_v2_Shopping.svg') no-repeat;
    background-size: contain;
}

.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-14"]:before
{
    background: url('/wp-content/uploads/2017/03/LuC_icons_v2_Kochen.svg') no-repeat;
    background-size: contain;
}

.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-1084"]:before
{
    background: url('/wp-content/uploads/2023/01/icon-vegan.svg') no-repeat;
    background-size: contain;
}

.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-19"]:before
{
    background: url('/wp-content/uploads/2017/03/LuC_icons_v2_Kochen.svg') no-repeat;
    background-size: contain;
}

.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-36"]:before
{
    background: url('/wp-content/uploads/2017/03/LuC_icons_v2_Shopping.svg') no-repeat;
    background-size: contain;
}

.post-filter ul.vc_grid-filter li.vc_grid-filter-item [data-vc-grid-filter-value=".vc_grid-term-18"]:before
{
    content: "\f16d";
    font-family: FontAwesome;
    font-size: 47px;
    top: 8px;
}

.vc_responsive .vc_grid-filter-select .vc_grid-styled-select
{
    max-width: 290px;
    width: 100%;
    border: none;
    height: 58px;
    position: relative;
    margin: 0 auto;

    &:after
    {
        content:'';
        background: url('images/angle-down-solid.svg');
        background-repeat: no-repeat;
        height: 30px;
        width: 20px;
        display: block;
        position: absolute;
        top: 10%;
        left: 88%;
    }
}

.vc_responsive .vc_grid-filter-select .vc_grid-styled-select option:not([value ='.vc_grid-term-14']):not([value ='.vc_grid-term-35']):not([value ='.vc_grid-term-775']):not([value ='.vc_grid-term-1084'])
{
    display: none!important;
}

.vc_responsive .vc_grid-filter-select select
{
    max-width: 290px;
    width: 100%;
    height: 45px;
    background: #fff;
    border: 2px solid $color-grey-dark;
    border-radius: 10px;
    font-size: 21px;
    font-family: 'HouschkaRoundedAlt-Medium';
    color: $color-grey-dark;
    text-align: center;
    padding:0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    overflow: hidden;

    option[value='.vc_grid-term-635'],
    option[value='.vc_grid-term-700'],
    option[value='.vc_grid-term-699'],
    option[value='.vc_grid-term-636'],
    option[value='.vc_grid-term-36'],
    option[value='.vc_grid-term-37']
    {
        display: none;
    }



    @media(max-width:1199px){
        padding:0 10px!important;
    }

}

.vc_grid-filter-dropdown .vc_grid-styled-select .vc_arrow-icon-navicon,
.vc_grid-filter-select .vc_grid-styled-select .vc_arrow-icon-navicon {
    display: none !important;
}

.vc_responsive .vc_grid-filter-select select::-ms-expand {
    display: none;
}

.vc_gitem-post-meta-field-cyberseo_post_link{
    display: none;
}

@media screen and (min-width: 768px)
{
    .post-filter  ul.vc_grid-filter
    {
        &:before
        {
            margin-right: 10px;
        }
    }
}
