/*scss variables and mixins*/
$color-white:#ffffff;
$color-black:#000000;
$color-orange: #e64316;
$color-orange-dark:#e84e0f;
$color-orange-light:#ee7326;
$color-grey: #6f6f6f;
$color-grey-second-light: #A8A8A8;
$color-grey-dark:#706f6f;
$color-grey-light:#c0bfbd;
$color-green: #165524;

@mixin font-houschka-medium
{
   font-family: 'HouschkaRoundedAlt-Medium';
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@mixin font-houschka-thin
{
   font-family: 'HouschkaRoundedAlt-Thin';
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@mixin font-houschka-bold
{
   font-family: 'HouschkaRoundedAlt-DemiBold';
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@mixin font-nunito
{
    font-family: 'Nunito';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@mixin font-gt-walsheim
{
    font-family: 'GT-Walsheim-Ultra-Bold';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@mixin font-cervo-medium
{
    font-family: 'CervoMedium';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@mixin small-text
{
    font-size: 20px;
}

@mixin flexbox
{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

@mixin flex-direction ($direction)
{
    -webkit-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

@mixin justify-content ($justify)
{

    -webkit-justify-content: $justify ;
    -ms-flex-pack: $justify;
    justify-content: $justify;
}

@mixin flex-wrap ($wrap)
{

     -webkit-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

@mixin flex-order($order)
{
    -webkit-order: $order;
    -ms-flex-order: $order;
    order: $order;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

@mixin flex
{
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}

@mixin button-style {
    color: $color-white;
    background: $color-orange-dark;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    opacity: 0.9;
    font-size: 15px;
    transform: all ease 0.3s;
    line-height: 57px;
    text-transform: uppercase;
    padding-top: 0;
    padding-bottom: 0;
    @include font-nunito;
    margin-right: 0;
    &:hover {
        opacity: 1;
    }
}
