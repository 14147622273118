@media(min-width: 540px)
{
    .haeppy-chips-header
    {
        padding-top: 0;
        min-height: 350px;
    }
}

@media(min-width: 768px)
{
    .haeppy-chips-header
    {
        min-height: 400px;
    }
}

@media(min-width: 1024px)
{
    .haeppy-chips-header
    {
        min-height: 500px;
    }
}

@media(min-width: 1200px)
{
    .haeppy-chips-header
    {
        min-height: 580px;
    }
}
