.form-select
{
    padding-top: 100px;
    @media(max-width: 767px)
    {
        padding-top: 50px !important;
    }
}

#Reklamation, #Kundenservice
{
    display: none;
}

#Kundenservice, #Reklamation, #Produktsuche
{
    br
    {
        display: none;
    }
}

.page-id-167 form
{
    max-width: 830px;
    margin: 0 auto;
}

span.wpcf7-not-valid-tip
{
    font-size: 12px;
}

body:not(.cf7p-message) .wpcf7-not-valid-tip
{
    position: static !important;
    left: 0 !important;
    top: 0 !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    background: none !important;
    overflow: hidden;
    text-indent: 0 !important;
    padding: 0;
    border: none !important;
    font-size: 12px;
    color: #e93842;
}

body:not(.cf7p-message) .wpcf7-validation-errors
{
    border: 2px solid #e93842 !important;
    display: block !important;
    text-align: center;
}

input[type="date"], input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], select
{
    border-color: none;
    border: 0;
    border-bottom: 1px solid #b3b0a3;
    background: none;
    box-shadow: none;
    opacity: 1;
    color: $color-grey-dark;
    @include font-houschka-medium;
    font-size: 18px;
    padding-left: 4px;

    &:focus
    {
        color: $color-grey-dark;
        background: none !important;
        box-shadow: none;
        border-bottom: 1px solid #e84e0f;
    }

    &.wpcf7-not-valid
    {
        border-bottom: 1px solid #e93842;
    }
}

#Wrapper select, textarea
{
    padding-left: 0;
}

#Wrapper .auswahl select
{
    border-bottom: 2px solid #e84e0f;
}

textarea
{
    background: none;
    box-shadow: 0 0 0 0;
    border: none;
    border-bottom: 1px solid #b3b0a3;
    margin-bottom: 20px !important;
    -webkit-box-shadow: 0 0 0 0;
    color: $color-grey-dark;
    @include font-houschka-medium;
    font-size: 18px;

    &:focus
    {
        background: none !important;
        color: $color-grey-dark;
    }

    &.wpcf7-not-valid
    {
        border-bottom: 1px solid #e93842;
    }
}

.head
{
    clear: both;
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;

    &.head_wo_erworben
    {
        margin-top: 90px;
        @media(max-width: 600px)
        {
            margin-top: 30px;
        }
    }

    &.head_mhd
    {
        margin-top: 30px;
    }

    &.head_wo_reklamiert
    {
        margin-top: 80px;
        @media(max-width: 600px)
        {
            margin-top: 30px;
        }
    }
}

#Wrapper
{

    span
    {

        &.wpcf7-form-control-wrap
        {
            width: 47%;
            margin-right: 3%;
            @media(max-width: 600px)
            {
                width: 100%;
                margin: 0;

                select
                {
                    width: 100%;
                    margin: 0;
                }
            }
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            margin-bottom: 0;
            display: inline-block;
            float: left;
        }

        &.auswahl
        {
            float: none;
            width: 100%;
            margin: 0;
        }

        &.plz_kundenservice, &.plz_reklamation, &.plz_produktsuche
        {
            width: 15%;
            margin-right: 3%;
            @media(max-width: 600px)
            {
                width: 100%;
                margin: 0;
            }
        }

        &.ort_kundenservice, &.ort_reklamation, &.ort_produktsuche
        {
            width: 29%;
            margin-right: 3%;
            @media(max-width: 600px)
            {
                width: 100%;
                margin: 0;
            }
        }

        &.anrede_kundenservice, &.anrede_reklamation, &.anrede_produktsuche, &.land_kundenservice
        {
            float: none;
        }


        &.anrede_kundenservice, &.anrede_reklamation, &.anrede_produktsuche
        {
            @media(max-width: 600px)
            {
                width: auto;
            }
        }

        &.land_kundenservice, &.land_reklamation, &.land_produktsuche
        {
            width: 100%;
        }


        &.upload_kundenservice, &.multifile-upload_reklamation, &.upload_produktsuche,
        &.zustimmung_kundenservice, &.zustimmung_reklamation, &.zustimmung_produktsuche,
        &.nachricht_kundenservice, &.nachricht_reklamation, &.nachricht_produktsuche
        {
            width: 100%;
        }
    }

    .anrede
    {

        width: 100%;
        clear: both;
        float: left;

        .anrede_head
        {
            float: left;
            margin-right: 20px;
            font-weight: normal;
        }

        input[type=radio]
        {
            position: absolute;
            visibility: hidden;
        }

        ul
        {
            list-style: none;
            margin: 0;
            padding: 0;
            float: left;
        }

        li
        {
            position: relative;
            display: inline-block;
            float: left;
        }

        .wpcf7-list-item-label
        {
            display: block;
            position: relative;
            padding: 0 25px 0 35px;
            @media(max-width: 320px)
            {
                padding: 0 15px 0 35px;
            }
            margin: 0 auto;
            height: 30px;
            z-index: 9;
            cursor: pointer;
            color: #706f6f;
            font-weight: normal;
        }

        .check
        {
            display: block;
            position: absolute;
            border: 2px solid #aaaaaa;
            border-radius: 100%;
            height: 23px;
            width: 23px;
            top: 3px;
            left: 0;
            z-index: 5;
            transition: border .25s linear;
            -webkit-transition: border .25s linear;

            &:before
            {
                display: block;
                position: absolute;
                content: '';
                border-radius: 100%;
                height: 11px;
                width: 11px;
                top: 4px;
                left: 4px;
                margin: auto;
                transition: background 0.25s linear;
                -webkit-transition: background 0.25s linear;
            }
        }

        ul li:hover .check
        {
            border: 2px solid #e8552c;
        }

        input[type=radio]:checked ~
        {
            .check
            {
                border: 2px solid #c1beb3;

                &:before
                {
                    background: #e8552c;
                }

            }

            label
            {
                font-weight: 700;
            }
        }
    }

    .mandatory-info
    {
        width: 100%;
        font-size: 12px;
        clear: both;
        margin-bottom: 20px;
    }

    .package-info
    {
        margin-top: 12px;
        display: inline-block;
        line-height: 24px;
        position: relative;


        &:before
        {
            content: '?';
            width: 23px;
            height: 26px;
            background: #e84e0f;
            display: inline-block;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            color: $color-white;
            position: relative;
            left: 5px;
            margin-right: 10px;
            font-size: 18px;
            text-align: center;
        }

        span
        {
            position: absolute;
            left: 40px;
        }

        .overlay
        {
            display: none;
        }

        &:hover
        {
            cursor: pointer;

            .overlay
            {
                display: block;
                position: absolute;
                left: 1px;
                top: 35px;
                @media(max-width: 960px)
                {
                    top: 55px;
                }
                width: 100%;
                height: 100%;
                z-index: 1;
            }
        }
    }

    .package-code-container
    {
        width: 47%;
        margin-right: 3%;
        float: left;
        @media(max-width: 600px)
        {
            width: 100%;
            margin: 0;
        }

        .packungscodierung_reklamation
        {
            width: 100%;
        }
    }

    .recaptcha
    {
        display: flex;
        width: 100%;
        margin: 20px 0;
    }

    .checkbox
    {

        input[type=checkbox]
        {
            width: 25px;
            height: 25px;
            visibility: hidden;
            float: left;
        }

        .wpcf7-list-item
        {
            position: relative;
            margin-left: 0;
            margin-right: 5px;

            > .wpcf7-list-item-label,
            input[type=checkbox] + span
            {
                display: inline-block;
                position: relative;
                border: 1px solid #a7a7a7;
                vertical-align: middle;
                cursor: pointer;
                box-sizing: border-box;
                font-weight: normal;
            }

            input[type='checkbox']:checked + span.wpcf7-list-item-label
            {
                &:before
                {
                    content: '';
                    background: url("images/check-solid.svg");
                    background-repeat: no-repeat;
                }
            }
        }


        .wpcf7-list-item-label
        {
            display: block;
            position: static !important;
            float: left !important;
            width: auto !important;
            height: auto !important;
            border: 0 !important;

            &:before
            {
                width: 25px;
                height: 25px;
                display: inline-block;
                position: absolute;
                left: 0;
                border: 1px solid #a7a7a7;
                vertical-align: middle;
                cursor: pointer;
                box-sizing: border-box;
            }
        }

        &.privacy
        {
            font-size: 12px;
        }

        span.wpcf7-form-control-wrap
        {
            width: auto;
            margin: 0;
        }

        &.checkbox_kanal
        {
            .wpcf7-list-item-label
            {
                line-height: 20px;
            }

            .wpcf7-form-control-wrap
            {
                width: 100%;
                margin-right: 0;
            }
        }
    }

    .checkbox-recaptcha
    {
        display: flex;
        width: 100%;
        margin: 20px 0 40px 0;

        span.wpcf7-form-control-wrap
        {
            //width: 32px;
            margin-right: 0;
        }

        .wpcf7-list-item
        {
            margin-right: 0;
        }

        .wpcf7-not-valid-tip
        {
            position: absolute;
            top: 250px;
            @media(min-width: 768px)
            {
                top: 120px;
            }

            @media(min-width: 1024px)
            {
                top: 90px;
            }

            width: 200px;
        }
    }
}

.styled-select
{
    background: url('/wp-content/uploads/2017/03/form_select_arrow.png') no-repeat right;
    display: block;
    width: 47%;

    &.land
    {
        display: inline-block;
        width: 47%;
        @media(max-width: 600px)
        {
            width: 100%;
        }
    }

    @media(max-width: 600px)
    {
        width: 100%;
    }
}

input[type="file"],
input[type="checkbox"],
select, input[type="number"]
{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    background: none;
    padding-left: 4px;
}

.submit
{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

input[type="submit"]
{
    width: 40%;
    margin: 0 auto !important;
    display: block;
    margin-top: 30px;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"]
{
    -moz-appearance: textfield;
}

::-webkit-input-placeholder
{
    color: $color-grey-dark;
    -webkit-opacity: 1;
}

::-moz-placeholder
{
    color: $color-grey-dark;
    opacity: 1;
}

:-ms-input-placeholder
{
    color: $color-grey-dark;
    opacity: 1;
}

:-moz-placeholder
{
    color: $color-grey-dark;
    opacity: 1;
}


/* hide browser-styling (arrow) in IE10 */
select::-ms-expand
{
    display: none;
}

#rc-imageselect, .g-recaptcha
{
    @media(max-width: 320px)
    {
        transform: scale(0.77);
        -webkit-transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

::-moz-selection
{
    background-color: none !important;
}

::selection
{
    background-color: none !important;
}

.ui-datepicker
{
    width: 260px;
    font-size: 13px;
}

.mtcaptcha
{
    clear:both;
}

#Wrapper #Reklamation
{
    span.wpcf7-form-control-wrap.nachricht
    {
        width: 100%;
        margin-right: 0;
    }
}
