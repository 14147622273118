@media screen and (min-width: 768px)
{
    .header-container
    {
        .header-text
        {
            width: calc(48% - 40px);
        }

        .slick-arrow
        {
            i
            {
                font-size: 40px;
            }
        }

        .slider_prev
        {
            left: 0;
        }

        .slider_next
        {
            right: 0;
        }
    }


}

@media screen and (min-width: 1024px)
{
    h1:not(haeppy-chips),.h1
    {
        font-size: 70px;
        line-height: 70px;
    }

    h1.haeppy-chips
    {
        font-size: 50px;
    }

    h2,.h2
    {
        font-size: 60px;
    }

    h3,.h3
    {
        font-size: 20px;
    }

    .facts.font-cervo-medium,
    p.font-cervo-medium
    {
        font-size: 28px;
    }

    .big-text
    {
        font-size: 24px;
    }

    .small-text
    {
        font-size: 18px;
    }
    .teaser-headline
    {
        font-size: 34px;
        line-height: 38px;
    }

    .what-we-do
    {
        .second-row
        {
            margin-top: -50px;
            padding: 0 13% 0 18%;
        }
    }

    .contact-us
    {
        padding-top: 50px;
    }
}

@media only screen and (min-width: 1240px)
{
    #Wrapper
    {
        #Header
        {
            #Top_bar
            {
                .menu > li
                {
                    .social-links
                    {
                        margin-left: 20px;
                        line-height: 137px;

                        a
                        {
                            margin-right: 5px;

                            &:last-child
                            {
                                margin-right: 0;
                            }

                            i
                            {
                                &:before
                                {
                                    color: #88909d;
                                }
                            }

                            &:hover
                            {
                                line-height: 0;
                            }
                         }

                    }
                }

                &.is-sticky
                {
                    .menu > li
                    {
                        .social-links
                        {
                            line-height: 80px;
                        }
                    }
                }
            }
        }
    }

    #Footer
    {
        .footer_copy
        {
            .social-menu
            {
                li
                {
                    &.job-link
                    {
                        display: inline-block;
                    }
                }
            }
        }
    }

}

@media screen and (min-width: 1400px)
{
    h1:not(.haeppy-chips),.h1
    {
        font-size: 110px;
        line-height: 110px;
    }
}

@media screen and (min-width: 1680px)
{
    .header-container
    {
        .header-text
        {
            width: 48%;
        }
    }
}

@media screen and (min-width: 1920px)
{
    .header-container
    {
        .slick-arrows
        {
            max-width: 1680px;
        }
    }
}
