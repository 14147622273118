/*/////////////////////////////////////////////////////////////////////////////////////////////////
//
// Compass
//
/////////////////////////////////////////////////////////////////////////////////////////////////
@import "compass/utilities";
@import "compass/typography";
@import "compass/css3";

@import 'variables-mixins';
@import 'form-styles';
@import 'filter-styles';
@import 'competition-styles';
*/
/*
Theme Name:     Betheme Child
Theme URI:      http://themes.muffingroup.com/betheme
Author:         Muffin group
Author URI:     http://muffingroup.com
Description:    Child Theme for Betheme - Premium WordPress Theme
Template:       betheme
Version:        1.6.1
*/

/* Theme customization starts here
-------------------------------------------------------------- */

.home_table {}
.home_table td { text-align: left; padding: 30px 0; }
.home_table td.t1 { color: #1a1a1a; font-size: 21px; line-height: 21px; width: 35%; border-right: 0; }
.home_table td a.button { margin-bottom: 0; }

body{
    @include font-houschka-medium;
    font-size: 18px;
    line-height: 30px;
    color:$color-grey;
}

h1:not(.haeppy-chips),h2,h3,h4,h5,h6{
text-transform:uppercase;
color:$color-orange-light;
font-weight:normal;
}

h1:not(.haeppy-chips),h2,h3,h4,.counter,.h1-style {
    @include font-houschka-thin;
}

h1.haeppy-chips
{
    @include font-gt-walsheim;
    font-size: 40px;
    line-height: normal;
    color: $color-green;
}

h5,h6 {
    @include font-houschka-medium;
}

h1,.counter,.h1-style{
    font-size: 100px;
    line-height: 90px;
}

h2{
    font-size: 75px;
    line-height: 68px;
}

h3{
    font-size:60px;
    line-height: 54px;
}

h4{
    font-size: 48px;
    line-height: 43px;
}

h5{
    font-size: 26px;
    line-height: 23px;
}

 h5 a, h5 a:hover{
color:$color-orange-light;
}

h6{
    font-size: 21px;
    line-height: 19px;
}

a, a:hover{
color:$color-orange-dark;
}

.font-gt-walsheim
{
    @include font-gt-walsheim;
}

.font-cervo-medium
{
    @include font-cervo-medium;
}

.facts.font-cervo-medium,
p.font-cervo-medium
{
    font-size: 24px;
}

.facts.font-cervo-medium ul
{
    color: $color-grey;
}

.video-wrapper
{
    position: relative;
    //padding-top: 30px;
    padding-bottom: 56.29%; /* 16:9 */
    height: 0;
    overflow: hidden;
    display: block;


    iframe
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

#Wrapper .button:not(.font-cervo-medium){
    @include button-style;
    white-space: nowrap;
}

#Wrapper .button.font-cervo-medium
{
    @include button-style;
    white-space: nowrap;
    font-family: 'CervoMedium';
    font-size: 24px;
}

#Wrapper .button-inactive
{
    @include button-style;
    transition: none;
    background: $color-grey-light;
    opacity: 1;

    &:after
    {
        background: none;
    }
}

.orange-text{
color:$color-orange-dark;
}

.font-bold
{
    @include font-houschka-bold;
    font-size:18px;
}

#Wrapper .list-unstyled{
  list-style: none;
  margin-left: 0;

      li{
        margin-bottom: 0;
    }
}


/*Header Startseite & Philosophie*/
    @media(max-width:767px){
    .section.full-width > .section_wrapper {
        max-width: calc(100% - 67px) !important;
    }
}

.competition-header-start
{
    .header-text
    {
        padding-top: 50px;
        padding-bottom: 70px;

        @media(max-width: 767px)
        {
            padding-bottom: 0;
        }
    }

}

.header-text{

    position: relative;
    z-index: 1;

    h1{
        @media(min-width:1405px){
            margin-left: -60px;
        }
    }

    padding-top: 110px;
        @media(max-width:1405px){
            padding-top: 70px;
        }
    p{
    margin-bottom:0;
    }

    .button-container
    {
        display: flex;
        justify-content: center;
    }

    @media(min-width:1405px){
        span{
        padding-left: 50px;
        display: block;
    }
    }

    @media(min-width:768px) and(max-width: 1024px){
        span{
        display: block;
    }
    }

    @media(min-width: 768px){
    .button{
        position: absolute;
        //left: 70%;
        margin-right: -50%;
        transform: translate(-50%,0);
        -webkit-transform: translate(-50%,0);
        left:50%;
        margin-top: 10px;
    }
    }


}

.header-image,
.header-image .image_item{
    margin-bottom:0;
}

.header-container{
    width:100%;

    .slick-arrow-container
    {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
    }

    .slick-arrows
    {
        margin: 0 auto;
        max-width: 1600px;
        height: 100%;
        position: relative;
    }

    .slick-arrow
    {
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        z-index: 10;

        i
        {
            font-size: 25px;
        }
    }

    .slider_prev
    {
        left: -30px;
    }

    .slider_next
    {
        right: -30px;
    }

    //.slide
    //{
    //    .mcb-wrap-inner
    //    {
    //        display: unset;
    //        width: unset;
    //    }
    //}

    .header-container-inner{
        max-width:1480px;
        margin:0 auto;
        background:$color-white;
        float: none;
        //display: none;

        .header-image{
            text-align: right;
            position: relative;
            @media(min-width:1500px){
             //left: 50px;
            }



            @media(min-width:1280px)
            {
                img
                {
                    width:75%;
                }
            }

            &.header-image-knusperviva
            {
               @media (min-width: 768px)
               {
                img
                {
                    width: 82%;
                }
               }

               @media(min-width: 1280px)
               {
                 img
                 {
                    width: 75%;
                    margin-left: 150px;
                 }
               }
            }
        }
    }

    .header-container-inner-video
    {
        max-width: 1100px;
    }
}

.gap-h3
{
    padding-top:100px;

    @media(max-width:767px){
        padding-top:70px;
    }
}


/*Anpassungen Menu*/

#Top_bar #logo, .header-fixed #Top_bar #logo, .header-plain #Top_bar #logo, .header-transparent #Top_bar #logo{padding-top:19px;}

#Wrapper #Header #Top_bar .menu > li a{text-transform:uppercase; font-size:18px; @include font-houschka-medium;}
#Wrapper #Header #Top_bar .menu > li a:hover{@include font-houschka-bold}
#Wrapper #Header #Top_bar  .sub-menu > li a{font-size:15px;padding-left:29px;}
#Wrapper #Header #Top_bar .menu  li.current-menu-item.menu-item-has-children{background:$color-orange-dark;}
#Wrapper #Header #Top_bar .menu  li.menu-item-has-children.hover a{background:$color-orange-dark;color:$color-white;transition:none;}
#Wrapper #Header #Top_bar .menu  li.current-menu-item.menu-item-has-children a{color:$color-white;}
#Wrapper #Header #Top_bar .menu  li.current-menu-item:not(.menu-item-has-children) a{@include font-houschka-bold;}
#Wrapper #Header #Top_bar .menu li ul li a { border-bottom: 1px solid #e9714f;}
#Wrapper #Header #Top_bar .menu li ul li{width:224px;}
#Wrapper #Header #Top_bar .menu li ul li ul {left: 224px;}
#Wrapper #Header #Top_bar .menu li ul li a .menu-arrow {color: $color-white;}
/*Anpassungen sticky Top Bar*/

#Wrapper #Header #Top_bar.is-sticky{
height:85px;
background: rgba(248, 247, 243, .9);
opacity:1;
    .logo{
    margin-top:-3px;
    }

    #logo{
    padding-top:0!important;
    }

    .menu_wrapper .menu > li > a{
        padding:25px 0;
    }
}

 #Wrapper #Header .menu-line-below-80 #Top_bar:not(.is-sticky) .menu > li > a::after {
  height: 7px;
  left: 10%;
  margin-top: 20px;
  top:40%;
  width: 100%;
}

 #Wrapper #Header #Top_bar .menu > li:not(.menu-item-has-children) a::after{
  background: rgba(0, 0, 0, 0) url("/wp-content/uploads/2017/03/menu_underline.png") no-repeat scroll 0 0;
  height: 7px;
  left: 10%;
  margin-top: 20px;
  top: 45%;
  width: 100%;
}

#Wrapper #Header #Top_bar .menu > li.menu-item-has-children a::after
{
    background:none;
}

/*Mobile Menu*/

#Top_bar a.responsive-menu-toggle{
    background:transparent;
    .icon-menu-fine{
        &:before{
            font-size: 28px;
        }
    }
}

#Side_slide .close-wrapper a.close i{
    &:before{
            font-size: 28px;
    }
}

#Side_slide #menu ul li.submenu .menu-toggle{
    opacity: 1;
}

#Side_slide #menu ul li a{
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0;
}

#Side_slide #menu ul li ul li a{
    font-size: 14px;
    padding-left: 30px;
}

#Side_slide
{
    #menu
    {
        ul li
        {
            &.current-menu-item,
            &.current-menu-ancestor
            {
                a
                {
                    font-weight: bold;
                }
            }

            .sub-menu li
            {
                a
                {
                    font-weight: normal;
                }

                &.current-menu-item
                {
                    a
                    {
                        font-weight: bold;
                    }
                }
            }

            .social-links
            {
                display: flex;

                a
                {
                    margin: 0;
                }

            }
        }
    }
}

.portfolio_group .portfolio-item .desc{
background:#f8f7f3;
padding-left:20px;
}

/*Anpassungen Subheader*/

#Subheader{
background:$color-white;
    &:after{
    background:none;
    }

    .title{
    @include font-houschka-thin;
    color:$color-orange-light;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    }

    //ausnahme nutzungsbedingungen
    .nutzungsbedingungen &{
        .title{
                @media(max-width:450px){
                    font-size: 26px;
                    text-overflow:initial;
                }

                 @media(max-width:400px){
                    font-size: 24px;
                    text-overflow:initial;
                }

                @media(max-width:360px){
                    font-size: 23px;
                }

                @media(max-width:359px){
                    font-size: 20px;
                }
            }
    }
}


/*Anpassungen Produkte*/

#Wrapper .portfolio_group.masonry .portfolio-item{

    img
    {
        transition: none;
        transform: none;
    }
}

.portfolio_group.masonry .portfolio-item .desc .title_wrapper {
    margin:15px 0;
}

.portfolio_group.masonry .portfolio-item .desc .desc-wrapper {
  color: $color-grey;
  display: block;
  @include font-houschka-medium;
  font-size: 15px;
  line-height: 24px;
  margin-right: 0;
}



/*Anpassungen Produkt Detailseite*/

body{
.fixed-nav{
    top:35%;
    position: absolute;
     .desc{
        display: none;
    }

    .photo{
        background:$color-white;

        @media(max-width: 1024px)
        {
            display: none!important;
        }
    }
}
}

.hinweise{
    font-size: 15px;
    margin-top: 30px;
    line-height: 22px;
}

.uppercase{
    text-transform: uppercase;
}

.single-portfolio p{
    font-size: 21px;
}

.single-portfolio h2{
    margin-bottom: 30px;
}

.detail-img-desktop{
    padding-top: 50px!important;
}

.detail-img-mobile{
    display: none;
}

.if-zoom .image_frame:hover .image_wrapper img,
.if-zoom .image_frame.hover .image_wrapper img {
    -moz-transform: scale(1.0);
    -webkit-transform: scale(1.0);
    -o-transform: scale(1.0);
}


@media(max-width: 767px){
    .detail-img-mobile{
        display: block;
    }

    .detail-img-desktop{
        display: none;
    }
}

.single-portfolio .ui-tabs .ui-tabs-nav li.ui-state-active a,
.single-portfolio .accordion .question.active .title > .acc-icon-plus,
.single-portfolio .accordion .question.active .title > .acc-icon-minus,
.single-portfolio .accordion .question.active .title,
.single-portfolio .accordion .question .title > .acc-icon-plus,
.single-portfolio .accordion .question .title > .acc-icon-minus {
  color: #e64315;
}

.single-portfolio .accordion .question .title > .acc-icon-plus,
.single-portfolio .accordion .question .title > .acc-icon-minus
{
    top: 32px;
}

.single-portfolio .accordion .title{
    font-size: 18px;
    @include font-houschka-bold;
    color:#e64315;
}

.single-portfolio .accordion .answer{
    font-size: 15px;
}

.single-portfolio .accordion .question{
    border-width: 0;
    border-top:2px solid #e5e1d5;
}

.single-portfolio .accordion .question::after{
    background:none;
}

.single-portfolio .produkt-detail-accordion .column{
    margin-bottom: 0;
}

.single-portfolio .produkt-detail-accordion .column_image{
  position: absolute;
  right: 0;
}

.single-portfolio .accordion .question .title{
    line-height: 83px;
    margin:0;
    padding-top:0;
    padding-bottom: 0;
    padding-left: 110px;
    padding-right: 0;
}

.single-portfolio .accordion .question .answer{
    padding-left: 110px;
    box-shadow: none;
}

.single-portfolio .zutaten .accordion .title::before,
.single-portfolio .allergiker-infos .accordion .title::before,
.single-portfolio .naehrwerttabelle .accordion .title::before{
    content:'';
    background:url(/wp-content/uploads/2017/03/icon_zutaten.png)no-repeat right center;
    left:40px;
    border:0;
    display: block;
}

.single-portfolio .allergiker-infos .accordion .title::before{
    background:url(/wp-content/uploads/2017/03/icon_informationen.png) no-repeat right center;
}

.single-portfolio .naehrwerttabelle .accordion .title::before{
    background:url(/wp-content/uploads/2017/03/icon_naehrwerttabelle.png) no-repeat right center;
    left:35px;
}

.single-portfolio.knusperviva-buchweizen,
.single-portfolio.knusperviva-quinoa
{
    .detail-img-desktop
    {
        padding: 0 !important;
    }

    .detail-img-mobile
    {
        img
        {
            padding: 30px 0;
        }
    }
}

.single-portfolio .naehrwerttabelle{

    .answer{
        padding-right: 0!important;
    }

    ul{
        list-style-type: none;
        width:100%;
        margin:0;
    }

    li{
        display: inline-block;
        width: 50%;
        @include font-houschka-bold;
        border-bottom:1px solid #e0e0df;

        &:nth-child(n+2):nth-child(odd){
           text-align: right;
        }

        &.no-border{
            border:0;
        }

        &.head{
            width:100%;
            text-align: right;
            border:0;
        }
    }
}

.text-container,
.single-portfolio .produkt-detail-text-container{
    padding:100px 0 100px 0!important;
}


.single-portfolio .passende-gerichte{
    padding-top:100px!important;

        .desc h4{
            font-size: 18px;
            line-height: 24px;
            @include font-houschka-bold;
            text-transform: none;
            text-align: center;

            a{
                color:$color-grey;
            }
        }

        .post_content{

            img{
                display: none;
            }

            text-align: center;
            font-size: 15px;
        }
}

.single-portfolio .passende-gerichte .blog_slider.flat .blog_slider_ul li .item_wrapper .date_label {display:none;}


.single-portfolio .blog_slider.flat .blog_slider_header_custom{
    position: absolute;
    top:45%;
    @media(max-width: 1024px){
        top:40%;
    }
    width:100%;

    .slider_prev,.slider_next{
        position: absolute;
        border:none;
        color:$color-orange-dark;
        font-size: 36px;

        &:hover{
            background:none;
        }
    }

    .slider_prev{
        left:-5%;
        @media(max-width: 767px){
            left:-35px;
        }
        right: auto;
    }

    .slider_next{
        left:auto;
        right:-5%;
        @media(max-width: 767px){
            right:-35px;
        }
    }
}



/*Beitrag Detailseite*/

.single-post .post-nav,
.single-post .fixed-nav,
.single-post .fbfpi_photo,
.single-post  .section-post-related{
    display:none;
}


.single-post{

    #Subheader{
        width: 100%
    }

    .title{
        max-width: 930px;
        margin:0 auto;
        float: none!important;

        @media(min-width: 1024px) and (max-width:1240px)
        {
            margin-top:30px;
        }
    }

    .share_wrapper{
        background:none;
        border:0;
        padding:0;
        @media(min-width:1280px){
        position: relative;
        left: 100px;
        }
    }

    @media(min-width: 1024px)
    {
        .shariff ul
        {
            flex-flow: column;
        }
    }

    .content_wrapper{
        padding-bottom: 50px;
    }

    .has_content{
        .section_wrapper{
            max-width: 820px;
        }
    }


    .erschienen-bei{
        display: none;
        p{
            font-size: 14px;
            color:$color-grey;
            opacity: 0.5;

        }
    }

    .post-detail{
        .section_wrapper{
            max-width: 960px;
        }
    }

    .fbfpi_text{
        margin-top: 20px;
    }
}

#menu-footer a{
    font-size:14px;
}

/*news grid Startseite*/

.news-grid{
    padding-top:50px;
    @media(max-width:768px){
        padding-top: 20px;
    }

    .vc_custom_heading{
        a{
            &:hover{
                opacity: 1;
            }
        }
    }

    .vc_btn3-container .vc_btn3
    {
        @include button-style;
        padding: 14px 20px;
        transition: none !important;

        &:hover
        {
            background: $color-orange-dark;
            opacity: 1;
            text-decoration: none;
        }
    }
}

.news-grid .vc_grid-filter{
    padding-bottom: 25px;
}

.news-grid .vc_grid-item-mini,
.recipe{
    padding:20px;
    background:#fff;
    box-shadow: 10px 10px 10px #f2f2f2;
    -webkit-box-shadow:10px 10px 10px #f2f2f2;
}

.news-grid .vc_gitem_row .vc_gitem-col{
    background:#fff;
    padding:10px 0;
}

.news-grid .vc_grid-item h4{
    font-size: 21px;
    @include font-nunito;
    line-height: 24px;
    margin-top: 10px;
    font-weight: normal;

    a
    {
        color: $color-orange-light;
    }
}

.news-grid .vc_gitem-post-data {
  @include font-nunito;
  font-size: 15px;
  line-height: 24px;
  margin-bottom:0;

}

.news-grid .vc_btn3-container .vc_btn3.vc_btn3-color-juicy-pink,
.news-grid .vc_btn3-container .vc_btn3.vc_btn3-color-juicy-pink:hover{
    background:none;
    color:$color-orange-dark;
    margin:0;
    padding:0;
    text-decoration: none;
    border-radius:0 0 0 0;
    -webkit-border-radius:0 0 0 0;
    border:0;
}

.source-image-container{
    width: 100%;
    max-width: 198px;
    //height:68px;
    margin: 0 auto;
}

.source-image,.source-link{
    text-align: center;
    margin:0 auto;
}

.source-image{
    position: relative;

    img{
        position: relative;
        z-index: 10;
        max-width:73px;
        max-height: 50px;
    }

    &:before,&:after{
            height:1px;
            color:#e9e9e9;
            content: "";
            display: block;
            width: 70px;
            border-top:1px solid #e9e9e9;
            position: absolute;
            top:18px;
            left:0;



            /*
            .single-post &{
                max-width: 180px;
                left:30%;

                @media(max-width: 400px){
                    left:25%;
                }
            }*/
    }

    &:after{
        left: auto;
        right:0;

        /*
        .single-post &{
            left:auto;
            right:30%;

                @media(max-width: 400px){
                    right:25%;
                }

        }*/
    }

}

.source-image-container-facebook,
.source-image-container-instagram{

    //height:75px;

    .source-image{
         &:before,&:after{
            top:30px;
        }

        &:after{
            right: 8px;
        }
    }
}

.source-container-rss {
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  font-size: 12px;
  text-align: center;
  //height: 75px;
  position: relative;

  &:before{
    height:1px;
    width:82px;
    border-top:1px solid #e9e9e9;
    content:'';
    position: absolute;
    top:0;
    margin-right: -50%;
    text-transform: translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
  }

  .source{
    padding-top:14px;
    span{
        font-weight: bold;
    }
  }

}


.source-link,.source-date,.source{
    @include font-nunito;
    font-size:12px;
    color:$color-grey;
}

/*Philosophie*/

.philosophie-content{


    padding-bottom: 140px!important;
    padding-top: 100px!important;

    @media(max-width:767px){
        padding-top:70px!important;
        padding-bottom: 70px!important;
    }


    h3{
        line-height: 60px;
        @media(max-width:1024px){
            line-height: 30px;
        }
    }

    .teaser{
        margin-bottom: 180px;
        @media(max-width: 767px){
            margin-bottom: 45px;
        }

        .font-bold{
            margin-bottom: 0;
        }
    }

    .image-content{
        @media(min-width: 768px){
            max-width: 570px;
        }

    }

    .fact-left,.fact-right{
        @media(min-width:768px){
            .column_attr{
                max-width: 360px;
            }
        }

        .font-bold{
            margin-top: 20px;
        }

    }

    .fact-left{
        @media(min-width:768px){
              .column_attr{
                margin: 0 auto;
            }
        }

    }

    .counter,.h1-style{
        color:$color-orange-light;
        font-weight:normal;
        @media(max-width:480px){
            font-weight: bold;
        }
        padding:0;
    }
}

.header-philosophie{
    h1{
        @media(min-width:1500px){
            margin-left: -20px;
        }
    }

    .header-text{
        position: relative;
    }
}

.header-philosophie .column_image,
.header-philosophie .image_frame{
    margin-bottom: 0;
}

/* imprint */

.imprint p,
.imprint p.font-bold
{
    line-height: 40px;
    margin: 0 0 35px 0;
}

.imprint{
    p
    {
    font-size: 18px;
    line-height: 30px;
    }

    a{
        @media(max-width: 480px){
            display: block;
        }
    }
}



/* privacy */

.privacy p,
.privacy p.font-bold
{
    line-height: 40px;
    margin: 0 0 35px 0;
}

.privacy p
{
    font-size: 18px;
    line-height: 30px;
}


/* terms */

.terms p,
.terms p.font-bold
{
    line-height: 40px;
    margin: 0 0 35px 0;
}

.terms p
{
    font-size: 18px;
    line-height: 30px;
}

.error404{
    background:#fff;
    padding:100px 0;
    overflow: hidden;

    .button{
         @include button-style;
         border-radius: 8px!important;
         -webkit-border-radius:8px!important;
         margin-top: 30px;
    }
}

#Footer
{
    .footer_copy
    {
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        .one
        {
            margin-bottom: 50px;
            padding-top: 60px;
        }

        .copyright
        {
            font-size:14px;
        }

        .social-menu
        {
            li
            {
                &.job-link
                {
                    display: block;
                }
            }

            a
            {
                color: $color-orange;

                &:hover
                {
                    color: $color-orange;
                }
            }
        }
    }
}

#cookie-notice{

    line-height: 40px;
    @media(max-width:768px){
        line-height: 20px;
    }

    .button{
        @include button-style;
        text-transform: none;
        line-height: 40px;
        margin-bottom: 0;
        font-size: 14px;
        position: absolute;
        @media(max-width:960px){
            display: block;
            margin: 0 auto;
            position: static;
            width: 120px;
        }

        @media(max-width:768px){
            display: block;
            float: none;
            margin: 10px auto 0;
        }
    }
}

.style-simple
{
    .faq
    {
        .question
        {
            .title
            {
                font-size: 18px;
                padding-left: 40px;
                color: $color-grey;

                .num
                {
                    display: none;
                }
            }

            &.active
            {
                .title
                {
                    color: $color-orange;
                }
            }

            .answer
            {
                padding-left: 40px;
            }
        }

    }
}

@media(max-width:1280px){
  h1,.counter,.h1-style
  {
    font-size: 50px;
    line-height: 50px;
  }

}

@media(max-width:1024px)
{
    h2,.passende-gerichte h4,.form-select h4
    {
    font-size: 40px;
    line-height: 40px;
    }

    h3{
    font-size: 30px;
    line-height: 30px;
    font-weight:bold;
    }

    h4{
    font-size: 26px;
    line-height: 26px;
    font-weight:bold;
    }

    h5{
    font-size: 24px;
    line-height: 24px;
    }

    h6{
    font-size: 22px;
    line-height: 22px;
    }

    #Subheader .title{
    font-size: 46px;
    line-height: 56px;
    font-weight: bold;
    }

    .text-container,
    .single-portfolio .produkt-detail-text-container
    {
    padding:50px 0!important;
    }

    .single-portfolio .passende-gerichte{
    padding-top:50px!important;
    }

    .news-grid .vc_grid-item{
    width:33.33333%;
    }

    #Wrapper .news-grid .vc_grid-item
    {
        margin-bottom: 20px;
    }

}

@media(max-width:768px){
    .news-grid .vc_grid-item{
    width:50%;
    }

    #Wrapper .portfolio_group.masonry .portfolio-item{
        width:48%;
    }
}


@media(max-width:767px){

    #Wrapper .news-grid .vc_grid-item
    {
        float: left;
    }

    #Wrapper .portfolio_group.masonry .portfolio-item{
        width:48%!important;
    }

    .header-text{
    padding-top: 30px;
    margin-bottom: 0;
}

/*Flexbox Startseite und Philosophie*/

    .home .mcb-wrap-inner,
    .header-philosophie .mcb-wrap-inner{
        @include flexbox;
}

    .home .mcb-wrap-inner .column_image,
    .header-philosophie .mcb-wrap-inner .column_image {
    @include flex-order(1);
}

.home .mcb-wrap-inner .header-text,
.header-philosophie .header-text{
    @include flex-order(0);
    }

    .single-portfolio .produkt-detail-accordion .column_image{
        display: none;
    }

     #Subheader .title{
    font-size: 40px;
    line-height: 50px;
  }
}



@media(max-width:480px){

    .news-grid .vc_grid-item{
    width:100%;
    float: none;
    }

    #Wrapper .portfolio_group.masonry .portfolio-item{
        width:100%!important;
    }

  .header-text h1{
    text-align:center;
  }

  h1,.counter,.h1-style{
    font-size: 37px;
    line-height: 37px;
    font-weight: bold;
  }

  h2,
  #Subheader .title,
  .passende-gerichte h4,
  .form-select h4 {
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
  }

  #Subheader .title
  {
    line-height: 40px;
  }

  #Wrapper #Subheader{
    padding:30px 0!important;
  }

  #Wrapper .button{
    line-height: 40px;
    font-size: 12px;
  }

  .home .mcb-wrap-inner .header-text{
    margin-bottom: 0;
  }

  h3{
    font-size: 30px;
    line-height: 30px;
  }
  h4{
    font-size: 28px;
    line-height: 28px;
  }
  h5{
    font-size: 26px;
    line-height: 26px;
  }

  h6{
    font-size: 24px;
    line-height: 24px;
  }

    .single-portfolio .accordion .question .answer{
        padding-left:18px;
    }
    .single-portfolio .accordion .allergiker-infos .question .title{
        line-height: 50px;
    }

}

@media(max-width:400px){
    .single-portfolio .accordion .question .title{
        padding-left: 60px;
    }

    .single-portfolio{
         .zutaten,.allergiker-infos,.naehrwerttabelle{
            .accordion{
                .title{
                    i{
                        left:0;
                    }
                }
            }
         }
    }

    .single-portfolio .zutaten .accordion .title::before,
    .single-portfolio .allergiker-infos .accordion .title::before,
    .single-portfolio .naehrwerttabelle .accordion .title::before{
        background-size: 40%;
        left:0;
        font-size: 16px;
    }


    .single-portfolio .accordion .question .answer{
        padding-left: 0;
    }
}

@media(max-width:350px){
    .single-portfolio .zutaten .accordion .title::before,
    .single-portfolio .allergiker-infos .accordion .title::before,
    .single-portfolio .naehrwerttabelle .accordion .title::before{
        background:none;
    }

    .single-portfolio .accordion .question .title{
       padding-left: 40px;
       font-size: 16px;
    }
}

.category
{
    #Filters
    {
        .tags,
        .authors
        {
            display: none;
        }
    }
}

.shariff-button.facebook
{
    .shariff-icon
    {
        margin-right: 10px;

        svg
        {
            display: none!important;
        }

        .fa-facebook-square
        {
          position: relative;
            top: 2px;
            left: 5px;

            &:before
            {
                font-size: 30px;
            }

        }
    }
}

/* mfn Icons */

@font-face{font-family:'mfn-icons';src:url('../fonts/mfn/icons.eot?31690507');src:url('../fonts/mfn/icons.eot?31690507#iefix') format("embedded-opentype"),url('../fonts/mfn/icons.woff?31690507') format("woff"),url('../fonts/mfn/icons.ttf?31690507') format("truetype"),url('../fonts/mfn/icons.svg?31690507#mfn') format("svg");font-weight:400;font-style:normal;font-display:swap}
[class^="icon-"]:before,[class*=" icon-"]:before{font-family:"mfn-icons";font-style:normal;font-weight:400;speak:none;display:inline-block;text-decoration:none!important;width:1em;margin-right:.2em;text-align:center;font-variant:normal;text-transform:none;line-height:1em;margin-left:.2em}

.icon-acrobat:before{content:'\e800'}.icon-address:before{content:'\e801'}.icon-adjust:before{content:'\e802'}.icon-aim:before{content:'\e803'}.icon-air:before{content:'\e804'}.icon-alert:before{content:'\e805'}.icon-amazon:before{content:'\e806'}.icon-android:before{content:'\e807'}.icon-angellist:before{content:'\e808'}
.icon-appnet:before{content:'\e809'}.icon-appstore:before{content:'\e80a'}.icon-archive:before{content:'\e80b'}.icon-arrow-combo:before{content:'\e80c'}.icon-arrows-ccw:before{content:'\e80d'}.icon-attach:before{content:'\e80e'}.icon-attach-line:before{content:'\e80f'}.icon-attention:before{content:'\e810'}.icon-back:before{content:'\e811'}
.icon-back-in-time:before{content:'\e812'}.icon-bag:before{content:'\e813'}.icon-basket:before{content:'\e814'}.icon-battery:before{content:'\e815'}.icon-beaker-line:before{content:'\e816'}.icon-bell:before{content:'\e817'}.icon-bitbucket:before{content:'\e818'}.icon-bitcoin:before{content:'\e819'}.icon-block:before{content:'\e81a'}
.icon-blogger:before{content:'\e81b'}.icon-book:before{content:'\e81c'}.icon-book-open:before{content:'\e81d'}.icon-bookmark:before{content:'\e81e'}.icon-bookmarks:before{content:'\e81f'}.icon-box:before{content:'\e820'}.icon-briefcase:before{content:'\e821'}.icon-brush:before{content:'\e822'}.icon-bucket:before{content:'\e823'}
.icon-buffer:before{content:'\e824'}.icon-calendar:before{content:'\e825'}.icon-calendar-line:before{content:'\e826'}.icon-call:before{content:'\e827'}.icon-camera:before{content:'\e828'}.icon-camera-line:before{content:'\e829'}.icon-cancel:before{content:'\e82a'}.icon-cancel-circled:before{content:'\e82b'}.icon-cancel-squared:before{content:'\e82c'}
.icon-cart:before{content:'\e82d'}.icon-cc:before{content:'\e82e'}.icon-cc-by:before{content:'\e82f'}.icon-cc-nc:before{content:'\e830'}.icon-cc-nc-eu:before{content:'\e831'}.icon-cc-nc-jp:before{content:'\e832'}.icon-cc-nd:before{content:'\e833'}.icon-cc-pd:before{content:'\e834'}.icon-cc-remix:before{content:'\e835'}
.icon-cc-sa:before{content:'\e836'}.icon-cc-share:before{content:'\e837'}.icon-cc-zero:before{content:'\e838'}.icon-ccw:before{content:'\e839'}.icon-cd:before{content:'\e83a'}.icon-cd-line:before{content:'\e83b'}.icon-chart-area:before{content:'\e83c'}.icon-chart-bar:before{content:'\e83d'}.icon-chart-line:before{content:'\e83e'}
.icon-chart-pie:before{content:'\e83f'}.icon-chat:before{content:'\e840'}.icon-check:before{content:'\e841'}.icon-clipboard:before{content:'\e842'}.icon-clock:before{content:'\e843'}.icon-clock-line:before{content:'\e844'}.icon-cloud:before{content:'\e845'}.icon-cloud-line:before{content:'\e846'}.icon-cloud-thunder:before{content:'\e847'}
.icon-cloudapp:before{content:'\e848'}.icon-code:before{content:'\e849'}.icon-cog:before{content:'\e84a'}.icon-cog-line:before{content:'\e84b'}.icon-comment-fa:before{content:'\e84c'}.icon-comment-line:before{content:'\e84d'}.icon-compass:before{content:'\e84e'}.icon-credit-card:before{content:'\e84f'}.icon-cup:before{content:'\e850'}
.icon-cup-line:before{content:'\e851'}.icon-cw:before{content:'\e852'}.icon-database-line:before{content:'\e853'}.icon-delicious:before{content:'\e854'}.icon-desktop-line:before{content:'\e855'}.icon-diamond-line:before{content:'\e856'}.icon-digg:before{content:'\e857'}.icon-direction:before{content:'\e858'}.icon-disqus:before{content:'\e859'}
.icon-doc:before{content:'\e85a'}.icon-doc-landscape:before{content:'\e85b'}.icon-doc-line:before{content:'\e85c'}.icon-doc-text:before{content:'\e85d'}.icon-doc-text-inv:before{content:'\e85e'}.icon-docs:before{content:'\e85f'}.icon-dot:before{content:'\e860'}.icon-dot-2:before{content:'\e861'}.icon-dot-3:before{content:'\e862'}
.icon-down:before{content:'\e863'}.icon-down-bold:before{content:'\e864'}.icon-down-circled:before{content:'\e865'}.icon-down-dir:before{content:'\e866'}.icon-down-open:before{content:'\e867'}.icon-down-open-big:before{content:'\e868'}.icon-down-open-mini:before{content:'\e869'}.icon-down-thin:before{content:'\e86a'}.icon-download:before{content:'\e86b'}
.icon-drive:before{content:'\e86c'}.icon-droplet:before{content:'\e86d'}.icon-drupal:before{content:'\e86e'}.icon-duckduckgo:before{content:'\e86f'}.icon-dwolla:before{content:'\e870'}.icon-ebay:before{content:'\e871'}.icon-email:before{content:'\e872'}.icon-erase:before{content:'\e873'}.icon-eventasaurus:before{content:'\e874'}
.icon-eventbrite:before{content:'\e875'}.icon-eventful:before{content:'\e876'}.icon-export:before{content:'\e877'}.icon-eye:before{content:'\e878'}.icon-eye-line:before{content:'\e879'}.icon-fast-backward:before{content:'\e87a'}.icon-fast-forward:before{content:'\e87b'}.icon-feather:before{content:'\e87c'}.icon-fire-line:before{content:'\e87d'}
.icon-fivehundredpx:before{content:'\e87e'}.icon-flag:before{content:'\e87f'}.icon-flash:before{content:'\e880'}.icon-flashlight:before{content:'\e881'}.icon-flight:before{content:'\e882'}.icon-floppy:before{content:'\e883'}.icon-flow-branch:before{content:'\e884'}.icon-flow-cascade:before{content:'\e885'}.icon-flow-line:before{content:'\e886'}
.icon-flow-parallel:before{content:'\e887'}.icon-flow-tree:before{content:'\e888'}.icon-folder:before{content:'\e889'}.icon-food-line:before{content:'\e88a'}.icon-forrst:before{content:'\e88b'}.icon-forward:before{content:'\e88c'}.icon-gauge:before{content:'\e88d'}.icon-globe:before{content:'\e88e'}.icon-globe-line:before{content:'\e88f'}
.icon-gmail:before{content:'\e890'}.icon-googleplay:before{content:'\e891'}.icon-gowalla:before{content:'\e892'}.icon-graduation-cap:before{content:'\e893'}.icon-graduation-cap-line:before{content:'\e894'}.icon-grooveshark:before{content:'\e895'}.icon-guest:before{content:'\e896'}.icon-hackernews:before{content:'\e897'}.icon-heart-empty-fa:before{content:'\e898'}
.icon-heart-fa:before{content:'\e899'}.icon-heart-line:before{content:'\e89a'}.icon-help:before{content:'\e89b'}.icon-help-circled:before{content:'\e89c'}.icon-home:before{content:'\e89d'}.icon-hourglass:before{content:'\e89e'}.icon-html5:before{content:'\e89f'}.icon-ie:before{content:'\e8a0'}.icon-inbox:before{content:'\e8a1'}
.icon-inbox-line:before{content:'\e8a2'}.icon-infinity:before{content:'\e8a3'}.icon-info:before{content:'\e8a4'}.icon-info-circled:before{content:'\e8a5'}.icon-install:before{content:'\e8a6'}.icon-instapaper:before{content:'\e8a7'}.icon-intensedebate:before{content:'\e8a8'}.icon-itunes:before{content:'\e8a9'}.icon-key:before{content:'\e8aa'}
.icon-key-line:before{content:'\e8ab'}.icon-keyboard:before{content:'\e8ac'}.icon-klout:before{content:'\e8ad'}.icon-lamp:before{content:'\e8ae'}.icon-language:before{content:'\e8af'}.icon-lanyrd:before{content:'\e8b0'}.icon-layout:before{content:'\e8b1'}.icon-leaf:before{content:'\e8b2'}.icon-left:before{content:'\e8b3'}
.icon-left-bold:before{content:'\e8b4'}.icon-left-circled:before{content:'\e8b5'}.icon-left-dir:before{content:'\e8b6'}.icon-left-open:before{content:'\e8b7'}.icon-left-open-big:before{content:'\e8b8'}.icon-left-open-mini:before{content:'\e8b9'}.icon-left-thin:before{content:'\e8ba'}.icon-lego:before{content:'\e8bb'}.icon-level-down:before{content:'\e8bc'}
.icon-level-up:before{content:'\e8bd'}.icon-lifebuoy:before{content:'\e8be'}.icon-light-down:before{content:'\e8bf'}.icon-light-up:before{content:'\e8c0'}.icon-lightbulb-line:before{content:'\e8c1'}.icon-link:before{content:'\e8c2'}.icon-list:before{content:'\e8c3'}.icon-list-add:before{content:'\e8c4'}.icon-lkdto:before{content:'\e8c5'}
.icon-location:before{content:'\e8c6'}.icon-location-line:before{content:'\e8c7'}.icon-lock:before{content:'\e8c8'}.icon-lock-line:before{content:'\e8c9'}.icon-lock-open:before{content:'\e8ca'}.icon-login:before{content:'\e8cb'}.icon-logout:before{content:'\e8cc'}.icon-loop:before{content:'\e8cd'}.icon-macstore:before{content:'\e8ce'}
.icon-magnet:before{content:'\e8cf'}.icon-mail:before{content:'\e8d0'}.icon-mail-line:before{content:'\e8d1'}.icon-map:before{content:'\e8d2'}.icon-meetup:before{content:'\e8d3'}.icon-megaphone:before{content:'\e8d4'}.icon-megaphone-line:before{content:'\e8d5'}.icon-menu:before{content:'\e8d6'}.icon-mic:before{content:'\e8d7'}
.icon-minus:before{content:'\e8d8'}.icon-minus-circled:before{content:'\e8d9'}.icon-minus-squared:before{content:'\e8da'}.icon-mobile:before{content:'\e8db'}.icon-mobile-line:before{content:'\e8dc'}.icon-money-line:before{content:'\e8dd'}.icon-monitor:before{content:'\e8de'}.icon-moon:before{content:'\e8df'}.icon-mouse:before{content:'\e8e0'}
.icon-music:before{content:'\e8e1'}.icon-music-line:before{content:'\e8e2'}.icon-mute:before{content:'\e8e3'}.icon-myspace:before{content:'\e8e4'}.icon-network:before{content:'\e8e5'}.icon-newspaper:before{content:'\e8e6'}.icon-ninetyninedesigns:before{content:'\e8e7'}.icon-note:before{content:'\e8e8'}.icon-note-beamed:before{content:'\e8e9'}
.icon-note-line:before{content:'\e8ea'}.icon-openid:before{content:'\e8eb'}.icon-opentable:before{content:'\e8ec'}.icon-palette:before{content:'\e8ed'}.icon-paper-plane:before{content:'\e8ee'}.icon-paper-plane-line:before{content:'\e8ef'}.icon-params-line:before{content:'\e8f0'}.icon-pause:before{content:'\e8f1'}.icon-pencil:before{content:'\e8f2'}
.icon-pencil-line:before{content:'\e8f3'}.icon-phone:before{content:'\e8f4'}.icon-photo-line:before{content:'\e8f5'}.icon-picture:before{content:'\e8f6'}.icon-pinboard:before{content:'\e8f7'}.icon-plancast:before{content:'\e8f8'}.icon-play:before{content:'\e8f9'}.icon-plurk:before{content:'\e8fa'}.icon-plus:before{content:'\e8fb'}
.icon-plus-circled:before{content:'\e8fc'}.icon-plus-squared:before{content:'\e8fd'}.icon-pocket:before{content:'\e8fe'}.icon-podcast:before{content:'\e8ff'}.icon-popup:before{content:'\e900'}.icon-posterous:before{content:'\e901'}.icon-print:before{content:'\e902'}.icon-progress-0:before{content:'\e903'}.icon-progress-1:before{content:'\e904'}
.icon-progress-2:before{content:'\e905'}.icon-progress-3:before{content:'\e906'}.icon-publish:before{content:'\e907'}.icon-quora:before{content:'\e908'}.icon-quote:before{content:'\e909'}.icon-record:before{content:'\e90a'}.icon-reddit:before{content:'\e90b'}.icon-reply:before{content:'\e90c'}.icon-reply-all:before{content:'\e90d'}
.icon-resize-full:before{content:'\e90e'}.icon-resize-small:before{content:'\e90f'}.icon-retweet:before{content:'\e910'}.icon-right:before{content:'\e911'}.icon-right-bold:before{content:'\e912'}.icon-right-circled:before{content:'\e913'}.icon-right-dir:before{content:'\e914'}.icon-right-open:before{content:'\e915'}.icon-right-open-big:before{content:'\e916'}
.icon-right-open-mini:before{content:'\e917'}.icon-right-thin:before{content:'\e918'}.icon-rocket:before{content:'\e919'}.icon-rss:before{content:'\e91a'}.icon-search:before{content:'\e91b'}.icon-search-line:before{content:'\e91c'}.icon-share:before{content:'\e91d'}.icon-shareable:before{content:'\e91e'}.icon-shop-line:before{content:'\e91f'}
.icon-shuffle:before{content:'\e920'}.icon-signal:before{content:'\e921'}.icon-smashmag:before{content:'\e922'}.icon-songkick:before{content:'\e923'}.icon-sound:before{content:'\e924'}.icon-sound-line:before{content:'\e925'}.icon-stackoverflow:before{content:'\e926'}.icon-star:before{content:'\e927'}.icon-star-empty:before{content:'\e928'}
.icon-star-line:before{content:'\e929'}.icon-statusnet:before{content:'\e92a'}.icon-stop:before{content:'\e92b'}.icon-suitcase:before{content:'\e92c'}.icon-switch:before{content:'\e92d'}.icon-t-shirt-line:before{content:'\e92e'}.icon-tag:before{content:'\e92f'}.icon-tag-line:before{content:'\e930'}.icon-tape:before{content:'\e931'}
.icon-target:before{content:'\e932'}.icon-thermometer:before{content:'\e933'}.icon-thumbs-up:before{content:'\e934'}.icon-thumbs-down:before{content:'\e935'}.icon-thumbs-up-line:before{content:'\e936'}.icon-ticket:before{content:'\e937'}.icon-to-end:before{content:'\e938'}.icon-to-start:before{content:'\e939'}.icon-tools:before{content:'\e93a'}
.icon-traffic-cone:before{content:'\e93b'}.icon-trash:before{content:'\e93c'}.icon-trash-line:before{content:'\e93d'}.icon-trophy:before{content:'\e93e'}.icon-truck-line:before{content:'\e93f'}.icon-tv-line:before{content:'\e940'}.icon-up:before{content:'\e941'}.icon-up-bold:before{content:'\e942'}.icon-up-circled:before{content:'\e943'}
.icon-up-dir:before{content:'\e944'}.icon-up-open:before{content:'\e945'}.icon-up-open-big:before{content:'\e946'}.icon-up-open-mini:before{content:'\e947'}.icon-up-thin:before{content:'\e948'}.icon-upload:before{content:'\e949'}.icon-upload-cloud:before{content:'\e94a'}.icon-user:before{content:'\e94b'}.icon-user-add:before{content:'\e94c'}
.icon-user-line:before{content:'\e94d'}.icon-users:before{content:'\e94e'}.icon-vcard:before{content:'\e94f'}.icon-viadeo:before{content:'\e950'}.icon-video:before{content:'\e951'}.icon-videocam-line:before{content:'\e952'}.icon-vk:before{content:'\e953'}.icon-volume:before{content:'\e954'}.icon-w3c:before{content:'\e955'}
.icon-wallet-line:before{content:'\e956'}.icon-water:before{content:'\e957'}.icon-weibo:before{content:'\e958'}.icon-wikipedia:before{content:'\e959'}.icon-window:before{content:'\e95a'}.icon-wordpress:before{content:'\e95b'}.icon-xing:before{content:'\e95c'}.icon-yahoo:before{content:'\e95d'}.icon-yelp:before{content:'\e95e'}
.icon-youtube:before{content:'\e95f'}.icon-menu-fine:before{content:'\e960'}.icon-bag-fine:before{content:'\e961'}.icon-search-fine:before{content:'\e962'}.icon-cancel-fine:before{content:'\e963'}.icon-plus-fine:before{content:'\e964'}.icon-minus-fine:before{content:'\e965'}.icon-gplus-circled:before{content:'\f059'}.icon-github-circled:before{content:'\f09b'}
.icon-gplus:before{content:'\f0d5'}.icon-comment-empty-fa:before{content:'\f0e5'}.icon-instagram:before{content:'\f16d'}.icon-tumblr:before{content:'\f173'}.icon-windows:before{content:'\f17a'}.icon-foursquare:before{content:'\f180'}.icon-google:before{content:'\f1a0'}.icon-behance:before{content:'\f1b4'}.icon-steam:before{content:'\f1b6'}
.icon-spotify:before{content:'\f1bc'}.icon-database:before{content:'\f1c0'}.icon-qq:before{content:'\f1d6'}.icon-paypal:before{content:'\f1ed'}.icon-stripe:before{content:'\f1f5'}.icon-whatsapp:before{content:'\f232'}.icon-medium:before{content:'\f23a'}.icon-tripadvisor:before{content:'\f262'}.icon-chrome:before{content:'\f268'}.icon-scribd:before{content:'\f28a'}
.icon-github:before{content:'\f300'}.icon-flickr:before{content:'\f303'}.icon-flickr-circled:before{content:'\f304'}.icon-vimeo:before{content:'\f306'}.icon-vimeo-circled:before{content:'\f307'}.icon-twitter:before{content:'\f309'}.icon-twitter-circled:before{content:'\f30a'}.icon-facebook:before{content:'\f30c'}.icon-facebook-circled:before{content:'\f30d'}
.icon-facebook-squared:before{content:'\f30e'}.icon-pinterest:before{content:'\f312'}.icon-pinterest-circled:before{content:'\f313'}.icon-tumblr-circled:before{content:'\f316'}.icon-linkedin:before{content:'\f318'}.icon-linkedin-circled:before{content:'\f319'}.icon-dribbble:before{content:'\f31b'}.icon-dribbble-circled:before{content:'\f31c'}.icon-stumbleupon:before{content:'\f31e'}
.icon-stumbleupon-circled:before{content:'\f31f'}.icon-lastfm:before{content:'\f321'}.icon-lastfm-circled:before{content:'\f322'}.icon-rdio:before{content:'\f324'}.icon-rdio-circled:before{content:'\f325'}.icon-spotify-circled:before{content:'\f328'}.icon-dropbox:before{content:'\f330'}.icon-evernote:before{content:'\f333'}.icon-flattr:before{content:'\f336'}
.icon-skype:before{content:'\f339'}.icon-skype-circled:before{content:'\f33a'}.icon-renren:before{content:'\f33c'}.icon-sina-weibo:before{content:'\f33f'}.icon-picasa:before{content:'\f345'}.icon-soundcloud:before{content:'\f348'}.icon-mixi:before{content:'\f34b'}.icon-google-circles:before{content:'\f351'}.icon-vkontakte:before{content:'\f354'}
.icon-smashing:before{content:'\f357'}.icon-db-shape:before{content:'\f600'}.icon-sweden:before{content:'\f601'}.icon-logo-db:before{content:'\f603'}.icon-houzz:before{content:'\f27c'}.icon-snapchat:before{content:'\f2ac'}
