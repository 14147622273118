.file-upload-info
{
    line-height: normal;
    text-align: left;

    @media(max-width: 480px)
    {
        font-size: 14px;
    }
}

.file-upload-container
{
    clear: both;
    margin: 0px 0 40px 0;
    padding-top: 50px;
    width: 100%;
}

.codedropz-upload-wrapper
{
    margin-bottom: 50px;
}

.codedropz-upload-inner
{
    white-space: normal;

    h3
    {
        font-weight: bold;
        text-transform: none;
    }
}

.dnd-upload-status
{
    .dnd-upload-details
    {
        font-size: 12px;

        .name
        {
            padding-right: 20px;
            color: $color-grey;
        }
    }
}

.codedropz-upload-wrapper
{
    span.has-error-msg
    {
        font-style: normal;
    }
}
