#Content .haeppy-chips-section
{
    .section_wrapper
    {
        max-width: none!important;
        padding: 0!important;
    }
}

.haeppy-chips-header
{
    padding-top: 30px;
    position: relative;
    width: 100%;
    min-height: 250px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .first-layer,
    .second-layer,
    .third-layer
    {
        position: absolute;
        opacity: 0;
        transform: translate(0,530px);
    }

    .first-layer
    {
        z-index: 1;
    }

    .second-layer
    {
        z-index: 2;
    }

    .third-layer
    {
        z-index: 3;
    }
}

.haeppy-chips-pea,
.haeppy-chips-lentil
{
    .section-post-header
    {
        display: none;
    }
}

.haeppy-chips-pea
{
    #Top_bar:not(.is-sticky)
    {
        background: #f5fddc!important;
    }

    .haeppy-chips-header
    {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f5fddc+0,c9e07f+100 */
        background: #f5fddc; /* Old browsers */
        background: -moz-linear-gradient(top,  #f5fddc 0%, #c9e07f 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #f5fddc 0%,#c9e07f 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #f5fddc 0%,#c9e07f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
}

.haeppy-chips-lentil
{
    #Top_bar
    {
        background: #f8e6e8!important;
    }

    .haeppy-chips-header
    {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f8e6e8+0,e994a0+100 */
        background: #f8e6e8; /* Old browsers */
        background: -moz-linear-gradient(top,  #f8e6e8 0%, #e994a0 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #f8e6e8 0%,#e994a0 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #f8e6e8 0%,#e994a0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
}
