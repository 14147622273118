/*!
 * WPBakery Page Builder v6.0.0 (https://wpbakery.com)
 * Copyright 2011-2019 Michael M, WPBakery
 * License: Commercial. More details: http://go.wpbakery.com/licensing
 */

.vc_row:after,
.vc_row:before {
    content: " ";
    display: table
}

.vc_row:after {
    clear: both
}
@media (max-width:1199px)
{
    .vc_responsive .vc_grid-filter
    {
        display: none !important;
    }
}

@media (max-width:767px) {
    .vc_hidden-xs {
        display: none !important
    }
}

@media (min-width:768px) and (max-width:991px) {
    .vc_hidden-sm {
        display: none !important
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .vc_hidden-md {
        display: none !important
    }
}

@media (min-width:1200px) {
    .vc_hidden-lg {
        display: none !important
    }
}

.vc_column_container {
    width: 100%
}

.vc_row
{
    margin-left: -15px;
    margin-right: -15px
}
.vc_masonry_grid .vc_grid.vc_row .vc_grid-item,
.vc_grid.vc_row .vc_grid-item
{
    display: none;
    vertical-align: top;
    float: none;
    box-sizing: border-box;
    z-index: 1
}

.vc_masonry_grid .vc_grid.vc_row .vc_grid-item.vc_visible-item,
.vc_masonry_media_grid .vc_grid.vc_row .vc_grid-item.vc_visible-item
{
    display: block
}

.vc_grid.vc_row.vc_grid-gutter-20px .vc_grid-item
{
    padding-right: 20px;
    padding-bottom: 20px
}

.vc_grid .vc_pageable-load-more-btn
{
    text-align: center;
    margin-top: 25px
}

.vc_grid .vc_pageable-load-more-btn > .vc_btn
{
    outline: 0
}

.vc_gitem_row
{
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding: inherit;
    box-sizing: border-box
}

.vc_col-lg-1,
.vc_col-lg-10,
.vc_col-lg-11,
.vc_col-lg-12,
.vc_col-lg-2,
.vc_col-lg-3,
.vc_col-lg-4,
.vc_col-lg-5,
.vc_col-lg-6,
.vc_col-lg-7,
.vc_col-lg-8,
.vc_col-lg-9,
.vc_col-md-1,
.vc_col-md-10,
.vc_col-md-11,
.vc_col-md-12,
.vc_col-md-2,
.vc_col-md-3,
.vc_col-md-4,
.vc_col-md-5,
.vc_col-md-6,
.vc_col-md-7,
.vc_col-md-8,
.vc_col-md-9,
.vc_col-sm-1,
.vc_col-sm-10,
.vc_col-sm-11,
.vc_col-sm-12,
.vc_col-sm-2,
.vc_col-sm-3,
.vc_col-sm-4,
.vc_col-sm-5,
.vc_col-sm-6,
.vc_col-sm-7,
.vc_col-sm-8,
.vc_col-sm-9,
.vc_col-xs-1,
.vc_col-xs-10,
.vc_col-xs-11,
.vc_col-xs-12,
.vc_col-xs-2,
.vc_col-xs-3,
.vc_col-xs-4,
.vc_col-xs-5,
.vc_col-xs-6,
.vc_col-xs-7,
.vc_col-xs-8,
.vc_col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.vc_col-xs-1,
.vc_col-xs-10,
.vc_col-xs-11,
.vc_col-xs-12,
.vc_col-xs-2,
.vc_col-xs-3,
.vc_col-xs-4,
.vc_col-xs-5,
.vc_col-xs-6,
.vc_col-xs-7,
.vc_col-xs-8,
.vc_col-xs-9 {
    float: left
}

[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-1,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-10,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-11,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-12,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-2,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-3,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-4,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-5,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-6,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-7,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-8,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-9 {
    float: right
}

.vc_col-xs-12 {
    width: 100%
}

.vc_col-xs-11 {
    width: 91.66666667%
}

.vc_col-xs-10 {
    width: 83.33333333%
}

.vc_col-xs-9 {
    width: 75%
}

.vc_col-xs-8 {
    width: 66.66666667%
}

.vc_col-xs-7 {
    width: 58.33333333%
}

.vc_col-xs-6 {
    width: 50%
}

.vc_col-xs-5 {
    width: 41.66666667%
}

.vc_col-xs-4 {
    width: 33.33333333%
}

.vc_col-xs-3 {
    width: 25%
}

.vc_col-xs-2 {
    width: 16.66666667%
}

.vc_col-xs-1 {
    width: 8.33333333%
}

.vc_col-xs-pull-12 {
    right: 100%
}

.vc_col-xs-pull-11 {
    right: 91.66666667%
}

.vc_col-xs-pull-10 {
    right: 83.33333333%
}

.vc_col-xs-pull-9 {
    right: 75%
}

.vc_col-xs-pull-8 {
    right: 66.66666667%
}

.vc_col-xs-pull-7 {
    right: 58.33333333%
}

.vc_col-xs-pull-6 {
    right: 50%
}

.vc_col-xs-pull-5 {
    right: 41.66666667%
}

.vc_col-xs-pull-4 {
    right: 33.33333333%
}

.vc_col-xs-pull-3 {
    right: 25%
}

.vc_col-xs-pull-2 {
    right: 16.66666667%
}

.vc_col-xs-pull-1 {
    right: 8.33333333%
}

.vc_col-xs-pull-0 {
    right: auto
}

.vc_col-xs-push-12 {
    left: 100%
}

.vc_col-xs-push-11 {
    left: 91.66666667%
}

.vc_col-xs-push-10 {
    left: 83.33333333%
}

.vc_col-xs-push-9 {
    left: 75%
}

.vc_col-xs-push-8 {
    left: 66.66666667%
}

.vc_col-xs-push-7 {
    left: 58.33333333%
}

.vc_col-xs-push-6 {
    left: 50%
}

.vc_col-xs-push-5 {
    left: 41.66666667%
}

.vc_col-xs-push-4 {
    left: 33.33333333%
}

.vc_col-xs-push-3 {
    left: 25%
}

.vc_col-xs-push-2 {
    left: 16.66666667%
}

.vc_col-xs-push-1 {
    left: 8.33333333%
}

.vc_col-xs-push-0 {
    left: auto
}

.vc_col-xs-offset-12 {
    margin-left: 100%
}

.vc_col-xs-offset-11 {
    margin-left: 91.66666667%
}

.vc_col-xs-offset-10 {
    margin-left: 83.33333333%
}

.vc_col-xs-offset-9 {
    margin-left: 75%
}

.vc_col-xs-offset-8 {
    margin-left: 66.66666667%
}

.vc_col-xs-offset-7 {
    margin-left: 58.33333333%
}

.vc_col-xs-offset-6 {
    margin-left: 50%
}

.vc_col-xs-offset-5 {
    margin-left: 41.66666667%
}

.vc_col-xs-offset-4 {
    margin-left: 33.33333333%
}

.vc_col-xs-offset-3 {
    margin-left: 25%
}

.vc_col-xs-offset-2 {
    margin-left: 16.66666667%
}

.vc_col-xs-offset-1 {
    margin-left: 8.33333333%
}

.vc_col-xs-offset-0 {
    margin-left: 0
}

.vc_col-lg-1\/5,
.vc_col-lg-2\/5,
.vc_col-lg-3\/5,
.vc_col-lg-4\/5,
.vc_col-lg-5\/5,
.vc_col-md-1\/5,
.vc_col-md-2\/5,
.vc_col-md-3\/5,
.vc_col-md-4\/5,
.vc_col-md-5\/5,
.vc_col-sm-1\/5,
.vc_col-sm-2\/5,
.vc_col-sm-3\/5,
.vc_col-sm-4\/5,
.vc_col-sm-5\/5,
.vc_col-xs-1\/5,
.vc_col-xs-2\/5,
.vc_col-xs-3\/5,
.vc_col-xs-4\/5,
.vc_col-xs-5\/5 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.vc_col-xs-1\/5,
.vc_col-xs-2\/5,
.vc_col-xs-3\/5,
.vc_col-xs-4\/5,
.vc_col-xs-5\/5 {
    float: left
}

.vc_col-xs-5\/5 {
    width: 100%
}

.vc_col-xs-4\/5 {
    width: 80%
}

.vc_col-xs-3\/5 {
    width: 60%
}

.vc_col-xs-2\/5 {
    width: 40%
}

.vc_col-xs-1\/5 {
    width: 20%
}

.vc_col-xs-pull-5\/5 {
    right: 100%
}

.vc_col-xs-pull-4\/5 {
    right: 80%
}

.vc_col-xs-pull-3\/5 {
    right: 60%
}

.vc_col-xs-pull-2\/5 {
    right: 40%
}

.vc_col-xs-pull-1\/5 {
    right: 20%
}

.vc_col-xs-pull-0\/5 {
    right: auto
}

.vc_col-xs-push-5\/5 {
    left: 100%
}

.vc_col-xs-push-4\/5 {
    left: 80%
}

.vc_col-xs-push-3\/5 {
    left: 60%
}

.vc_col-xs-push-2\/5 {
    left: 40%
}

.vc_col-xs-push-1\/5 {
    left: 20%
}

.vc_col-xs-push-0\/5 {
    left: auto
}

.vc_col-xs-offset-5\/5 {
    margin-left: 100%
}

.vc_col-xs-offset-4\/5 {
    margin-left: 80%
}

.vc_col-xs-offset-3\/5 {
    margin-left: 60%
}

.vc_col-xs-offset-2\/5 {
    margin-left: 40%
}

.vc_col-xs-offset-1\/5 {
    margin-left: 20%
}

.vc_col-xs-offset-0\/5 {
    margin-left: 0
}

[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-1\/5,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-2\/5,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-3\/5,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-4\/5,
[dir=rtl] .vc_rtl-columns-reverse .vc_col-xs-5\/5,
[dir=rtl] .vc_rtl-columns-reverse .vc_non_responsive .vc_row .vc_col-sm-1\/5,
[dir=rtl] .vc_rtl-columns-reverse .vc_non_responsive .vc_row .vc_col-sm-2\/5,
[dir=rtl] .vc_rtl-columns-reverse .vc_non_responsive .vc_row .vc_col-sm-3\/5,
[dir=rtl] .vc_rtl-columns-reverse .vc_non_responsive .vc_row .vc_col-sm-4\/5,
[dir=rtl] .vc_rtl-columns-reverse .vc_non_responsive .vc_row .vc_col-sm-5\/5 {
    float: right
}

@media (min-width:768px) {
    .vc_col-sm-1,
    .vc_col-sm-10,
    .vc_col-sm-11,
    .vc_col-sm-12,
    .vc_col-sm-2,
    .vc_col-sm-3,
    .vc_col-sm-4,
    .vc_col-sm-5,
    .vc_col-sm-6,
    .vc_col-sm-7,
    .vc_col-sm-8,
    .vc_col-sm-9 {
        float: left
    }
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-1,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-10,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-11,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-12,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-2,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-3,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-4,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-6,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-7,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-8,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-9 {
        float: right
    }
    .vc_col-sm-12 {
        width: 100%
    }
    .vc_col-sm-11 {
        width: 91.66666667%
    }
    .vc_col-sm-10 {
        width: 83.33333333%
    }
    .vc_col-sm-9 {
        width: 75%
    }
    .vc_col-sm-8 {
        width: 66.66666667%
    }
    .vc_col-sm-7 {
        width: 58.33333333%
    }
    .vc_col-sm-6 {
        width: 50%
    }
    .vc_col-sm-5 {
        width: 41.66666667%
    }
    .vc_col-sm-4 {
        width: 33.33333333%
    }
    .vc_col-sm-3 {
        width: 25%
    }
    .vc_col-sm-2 {
        width: 16.66666667%
    }
    .vc_col-sm-1 {
        width: 8.33333333%
    }
    .vc_col-sm-pull-12 {
        right: 100%
    }
    .vc_col-sm-pull-11 {
        right: 91.66666667%
    }
    .vc_col-sm-pull-10 {
        right: 83.33333333%
    }
    .vc_col-sm-pull-9 {
        right: 75%
    }
    .vc_col-sm-pull-8 {
        right: 66.66666667%
    }
    .vc_col-sm-pull-7 {
        right: 58.33333333%
    }
    .vc_col-sm-pull-6 {
        right: 50%
    }
    .vc_col-sm-pull-5 {
        right: 41.66666667%
    }
    .vc_col-sm-pull-4 {
        right: 33.33333333%
    }
    .vc_col-sm-pull-3 {
        right: 25%
    }
    .vc_col-sm-pull-2 {
        right: 16.66666667%
    }
    .vc_col-sm-pull-1 {
        right: 8.33333333%
    }
    .vc_col-sm-pull-0 {
        right: auto
    }
    .vc_col-sm-push-12 {
        left: 100%
    }
    .vc_col-sm-push-11 {
        left: 91.66666667%
    }
    .vc_col-sm-push-10 {
        left: 83.33333333%
    }
    .vc_col-sm-push-9 {
        left: 75%
    }
    .vc_col-sm-push-8 {
        left: 66.66666667%
    }
    .vc_col-sm-push-7 {
        left: 58.33333333%
    }
    .vc_col-sm-push-6 {
        left: 50%
    }
    .vc_col-sm-push-5 {
        left: 41.66666667%
    }
    .vc_col-sm-push-4 {
        left: 33.33333333%
    }
    .vc_col-sm-push-3 {
        left: 25%
    }
    .vc_col-sm-push-2 {
        left: 16.66666667%
    }
    .vc_col-sm-push-1 {
        left: 8.33333333%
    }
    .vc_col-sm-push-0 {
        left: auto
    }
    .vc_col-sm-offset-12 {
        margin-left: 100%
    }
    .vc_col-sm-offset-11 {
        margin-left: 91.66666667%
    }
    .vc_col-sm-offset-10 {
        margin-left: 83.33333333%
    }
    .vc_col-sm-offset-9 {
        margin-left: 75%
    }
    .vc_col-sm-offset-8 {
        margin-left: 66.66666667%
    }
    .vc_col-sm-offset-7 {
        margin-left: 58.33333333%
    }
    .vc_col-sm-offset-6 {
        margin-left: 50%
    }
    .vc_col-sm-offset-5 {
        margin-left: 41.66666667%
    }
    .vc_col-sm-offset-4 {
        margin-left: 33.33333333%
    }
    .vc_col-sm-offset-3 {
        margin-left: 25%
    }
    .vc_col-sm-offset-2 {
        margin-left: 16.66666667%
    }
    .vc_col-sm-offset-1 {
        margin-left: 8.33333333%
    }
    .vc_col-sm-offset-0 {
        margin-left: 0
    }
    .vc_col-sm-1\/5,
    .vc_col-sm-2\/5,
    .vc_col-sm-3\/5,
    .vc_col-sm-4\/5,
    .vc_col-sm-5\/5 {
        float: left
    }
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-1\/5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-2\/5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-3\/5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-4\/5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-5\/5 {
        float: right
    }
    .vc_col-sm-5\/5 {
        width: 100%
    }
    .vc_col-sm-4\/5 {
        width: 80%
    }
    .vc_col-sm-3\/5 {
        width: 60%
    }
    .vc_col-sm-2\/5 {
        width: 40%
    }
    .vc_col-sm-1\/5 {
        width: 20%
    }
    .vc_col-sm-pull-5\/5 {
        right: 100%
    }
    .vc_col-sm-pull-4\/5 {
        right: 80%
    }
    .vc_col-sm-pull-3\/5 {
        right: 60%
    }
    .vc_col-sm-pull-2\/5 {
        right: 40%
    }
    .vc_col-sm-pull-1\/5 {
        right: 20%
    }
    .vc_col-sm-pull-0\/5 {
        right: auto
    }
    .vc_col-sm-push-5\/5 {
        left: 100%
    }
    .vc_col-sm-push-4\/5 {
        left: 80%
    }
    .vc_col-sm-push-3\/5 {
        left: 60%
    }
    .vc_col-sm-push-2\/5 {
        left: 40%
    }
    .vc_col-sm-push-1\/5 {
        left: 20%
    }
    .vc_col-sm-push-0\/5 {
        left: auto
    }
    .vc_col-sm-offset-5\/5 {
        margin-left: 100%
    }
    .vc_col-sm-offset-4\/5 {
        margin-left: 80%
    }
    .vc_col-sm-offset-3\/5 {
        margin-left: 60%
    }
    .vc_col-sm-offset-2\/5 {
        margin-left: 40%
    }
    .vc_col-sm-offset-1\/5 {
        margin-left: 20%
    }
    .vc_col-sm-offset-0\/5 {
        margin-left: 0
    }
}

@media (min-width:992px) {
    .vc_col-md-1,
    .vc_col-md-10,
    .vc_col-md-11,
    .vc_col-md-12,
    .vc_col-md-2,
    .vc_col-md-3,
    .vc_col-md-4,
    .vc_col-md-5,
    .vc_col-md-6,
    .vc_col-md-7,
    .vc_col-md-8,
    .vc_col-md-9 {
        float: left
    }
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-1,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-10,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-11,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-12,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-2,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-3,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-4,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-6,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-7,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-8,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-md-9 {
        float: right
    }
    .vc_col-md-12 {
        width: 100%
    }
    .vc_col-md-11 {
        width: 91.66666667%
    }
    .vc_col-md-10 {
        width: 83.33333333%
    }
    .vc_col-md-9 {
        width: 75%
    }
    .vc_col-md-8 {
        width: 66.66666667%
    }
    .vc_col-md-7 {
        width: 58.33333333%
    }
    .vc_col-md-6 {
        width: 50%
    }
    .vc_col-md-5 {
        width: 41.66666667%
    }
    .vc_col-md-4 {
        width: 33.33333333%
    }
    .vc_col-md-3 {
        width: 25%
    }
    .vc_col-md-2 {
        width: 16.66666667%
    }
    .vc_col-md-1 {
        width: 8.33333333%
    }
    .vc_col-md-pull-12 {
        right: 100%
    }
    .vc_col-md-pull-11 {
        right: 91.66666667%
    }
    .vc_col-md-pull-10 {
        right: 83.33333333%
    }
    .vc_col-md-pull-9 {
        right: 75%
    }
    .vc_col-md-pull-8 {
        right: 66.66666667%
    }
    .vc_col-md-pull-7 {
        right: 58.33333333%
    }
    .vc_col-md-pull-6 {
        right: 50%
    }
    .vc_col-md-pull-5 {
        right: 41.66666667%
    }
    .vc_col-md-pull-4 {
        right: 33.33333333%
    }
    .vc_col-md-pull-3 {
        right: 25%
    }
    .vc_col-md-pull-2 {
        right: 16.66666667%
    }
    .vc_col-md-pull-1 {
        right: 8.33333333%
    }
    .vc_col-md-pull-0 {
        right: auto
    }
    .vc_col-md-push-12 {
        left: 100%
    }
    .vc_col-md-push-11 {
        left: 91.66666667%
    }
    .vc_col-md-push-10 {
        left: 83.33333333%
    }
    .vc_col-md-push-9 {
        left: 75%
    }
    .vc_col-md-push-8 {
        left: 66.66666667%
    }
    .vc_col-md-push-7 {
        left: 58.33333333%
    }
    .vc_col-md-push-6 {
        left: 50%
    }
    .vc_col-md-push-5 {
        left: 41.66666667%
    }
    .vc_col-md-push-4 {
        left: 33.33333333%
    }
    .vc_col-md-push-3 {
        left: 25%
    }
    .vc_col-md-push-2 {
        left: 16.66666667%
    }
    .vc_col-md-push-1 {
        left: 8.33333333%
    }
    .vc_col-md-push-0 {
        left: auto
    }
    .vc_col-md-offset-12 {
        margin-left: 100%
    }
    .vc_col-md-offset-11 {
        margin-left: 91.66666667%
    }
    .vc_col-md-offset-10 {
        margin-left: 83.33333333%
    }
    .vc_col-md-offset-9 {
        margin-left: 75%
    }
    .vc_col-md-offset-8 {
        margin-left: 66.66666667%
    }
    .vc_col-md-offset-7 {
        margin-left: 58.33333333%
    }
    .vc_col-md-offset-6 {
        margin-left: 50%
    }
    .vc_col-md-offset-5 {
        margin-left: 41.66666667%
    }
    .vc_col-md-offset-4 {
        margin-left: 33.33333333%
    }
    .vc_col-md-offset-3 {
        margin-left: 25%
    }
    .vc_col-md-offset-2 {
        margin-left: 16.66666667%
    }
    .vc_col-md-offset-1 {
        margin-left: 8.33333333%
    }
    .vc_col-md-offset-0 {
        margin-left: 0
    }
    .vc_col-md-1\/5,
    .vc_col-md-2\/5,
    .vc_col-md-3\/5,
    .vc_col-md-4\/5,
    .vc_col-md-5\/5 {
        float: left
    }
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-1\/5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-2\/5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-3\/5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-4\/5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-sm-5\/5 {
        float: right
    }
    .vc_col-md-5\/5 {
        width: 100%
    }
    .vc_col-md-4\/5 {
        width: 80%
    }
    .vc_col-md-3\/5 {
        width: 60%
    }
    .vc_col-md-2\/5 {
        width: 40%
    }
    .vc_col-md-1\/5 {
        width: 20%
    }
    .vc_col-md-pull-5\/5 {
        right: 100%
    }
    .vc_col-md-pull-4\/5 {
        right: 80%
    }
    .vc_col-md-pull-3\/5 {
        right: 60%
    }
    .vc_col-md-pull-2\/5 {
        right: 40%
    }
    .vc_col-md-pull-1\/5 {
        right: 20%
    }
    .vc_col-md-pull-0\/5 {
        right: auto
    }
    .vc_col-md-push-5\/5 {
        left: 100%
    }
    .vc_col-md-push-4\/5 {
        left: 80%
    }
    .vc_col-md-push-3\/5 {
        left: 60%
    }
    .vc_col-md-push-2\/5 {
        left: 40%
    }
    .vc_col-md-push-1\/5 {
        left: 20%
    }
    .vc_col-md-push-0\/5 {
        left: auto
    }
    .vc_col-md-offset-5\/5 {
        margin-left: 100%
    }
    .vc_col-md-offset-4\/5 {
        margin-left: 80%
    }
    .vc_col-md-offset-3\/5 {
        margin-left: 60%
    }
    .vc_col-md-offset-2\/5 {
        margin-left: 40%
    }
    .vc_col-md-offset-1\/5 {
        margin-left: 20%
    }
    .vc_col-md-offset-0\/5 {
        margin-left: 0
    }
}

@media (min-width:1200px)
{
    .vc_col-lg-1,
    .vc_col-lg-10,
    .vc_col-lg-11,
    .vc_col-lg-12,
    .vc_col-lg-2,
    .vc_col-lg-3,
    .vc_col-lg-4,
    .vc_col-lg-5,
    .vc_col-lg-6,
    .vc_col-lg-7,
    .vc_col-lg-8,
    .vc_col-lg-9
    {
        float: left
    }
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-1,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-10,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-11,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-12,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-2,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-3,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-4,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-5,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-6,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-7,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-8,
    [dir=rtl] .vc_rtl-columns-reverse .vc_col-lg-9
    {
        float: right
    }
    .vc_col-lg-12
    {
        width: 100%
    }
    .vc_col-lg-11
    {
        width: 91.66666667%
    }
    .vc_col-lg-10
    {
        width: 83.33333333%
    }
    .vc_col-lg-9
    {
        width: 75%
    }
    .vc_col-lg-8
    {
        width: 66.66666667%
    }
    .vc_col-lg-7
    {
        width: 58.33333333%
    }
    .vc_col-lg-6
    {
        width: 50%
    }
    .vc_col-lg-5
    {
        width: 41.66666667%
    }
    .vc_col-lg-4
    {
        width: 33.33333333%
    }
    .vc_col-lg-3
    {
        width: 25%
    }
    .vc_col-lg-2
    {
        width: 16.66666667%
    }
    .vc_col-lg-1
    {
        width: 8.33333333%
    }


    .vc_clearfix:after,
    .vc_clearfix:before
    {
        content: " ";
        display: table
    }

    .vc_clearfix:after
    {
        clear: both
    }

    .vc_clearfix:after,
    .vc_clearfix:before
    {
        content: " ";
        display: table
    }

    .vc_clearfix:after
    {
        clear: both
    }

    .vc_el-clearfix
    {
        clear: both
    }

    @media (max-width: 767px)
    {
        .vc_el-clearfix-xs
        {
            clear: both
        }
    }

    @media (min-width: 768px) and (max-width: 991px)
    {
        .vc_el-clearfix-sm
        {
            clear: both
        }
    }

    @media (min-width: 992px) and (max-width: 1199px)
    {
        .vc_el-clearfix-md
        {
            clear: both
        }
    }

    @media (min-width: 1200px)
    {
        .vc_el-clearfix-lg
        {
            clear: both
        }
    }

    .vc_visible
    {
        display: block
    }

    .vc_btn,
    a.vc_btn,
    button.vc_btn
    {
        cursor: pointer;
        display: inline-block;
        text-decoration: none !important;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        padding: 9px 12px;
        font-size: 100%;
        line-height: 20px;
        border-radius: 4px
    }

    .vc_btn,
    .vc_btn:focus,
    .vc_btn:hover,
    a.vc_btn,
    a.vc_btn:focus,
    a.vc_btn:hover,
    button.vc_btn,
    button.vc_btn:focus,
    button.vc_btn:hover
    {
        border: 1px solid transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }

    .vc_btn-lg,
    a.vc_btn-lg,
    button.vc_btn-lg
    {
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.33;
        border-radius: 6px
    }

    .vc_row.vc_row-no-padding .vc_column-inner
    {
        padding-left: 0;
        padding-right: 0
    }

    .vc_row[data-vc-full-width]
    {
        -webkit-transition: opacity .5s ease;
        -o-transition: opacity .5s ease;
        transition: opacity .5s ease;
        overflow: hidden
    }

    .vc_row[data-vc-full-width].vc_hidden
    {
        opacity: 0
    }

    .vc_row-no-padding .vc_inner
    {
        margin-left: 0;
        margin-right: 0
    }

    .vc_row.vc_row-o-full-height
    {
        min-height: 100vh
    }

    .vc_row.vc_row-flex
    {
        box-sizing: border-box;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .vc_row.vc_row-flex > .vc_column_container
    {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }

    .vc_row.vc_row-flex > .vc_column_container > .vc_column-inner
    {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        z-index: 1
    }

    @media (-ms-high-contrast: active),
    (-ms-high-contrast: none)
    {
        .vc_row.vc_row-flex > .vc_column_container > .vc_column-inner > *
        {
            min-height: 1em
        }
    }

    .vc_row.vc_row-flex::after,
    .vc_row.vc_row-flex::before
    {
        display: none
    }

    .vc_row.vc_row-o-columns-stretch
    {
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch
    }

    .vc_row.vc_row-o-columns-top
    {
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start
    }

    .vc_row.vc_row-o-columns-bottom
    {
        -webkit-align-content: flex-end;
        -ms-flex-line-pack: end;
        align-content: flex-end
    }

    .vc_row.vc_row-o-columns-middle
    {
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center
    }

    .vc_row.vc_row-o-columns-bottom::after,
    .vc_row.vc_row-o-columns-middle::after,
    .vc_row.vc_row-o-columns-top::after
    {
        content: '';
        width: 100%;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        display: block
    }

    .vc_row.vc_row-o-equal-height > .vc_column_container
    {
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .vc_row.vc_row-o-content-top > .vc_column_container > .vc_column-inner
    {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .vc_row.vc_row-o-content-top:not(.vc_row-o-equal-height) > .vc_column_container
    {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .vc_row.vc_row-o-content-bottom > .vc_column_container > .vc_column-inner
    {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .vc_row.vc_row-o-content-bottom:not(.vc_row-o-equal-height) > .vc_column_container
    {
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .vc_row.vc_row-o-content-middle > .vc_column_container > .vc_column-inner
    {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .vc_row.vc_row-o-content-middle:not(.vc_row-o-equal-height) > .vc_column_container
    {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .vc_column-inner::after,
    .vc_column-inner::before
    {
        content: " ";
        display: table
    }

    .vc_column-inner::after
    {
        clear: both
    }

    .vc_ie-flexbox-fixer
    {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .vc_ie-flexbox-fixer > .vc_row
    {
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto
    }

    .vc_row.vc_column-gap-20
    {
        margin-left: -25px;
        margin-right: -25px
    }

    .vc_row.vc_column-gap-20 > .vc_column_container
    {
        padding: 10px
    }

    .vc_column_container
    {
        padding-left: 0;
        padding-right: 0
    }

    .vc_column_container > .vc_column-inner
    {
        box-sizing: border-box;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%
    }

    .vc_col-has-fill > .vc_column-inner,
    .vc_row-has-fill + .vc_row-full-width + .vc_row > .vc_column_container > .vc_column-inner,
    .vc_row-has-fill + .vc_row > .vc_column_container > .vc_column-inner,
    .vc_row-has-fill > .vc_column_container > .vc_column-inner
    {
        padding-top: 35px
    }

    .vc_section
    {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: -15px;
        margin-right: -15px
    }

    .vc_section[data-vc-full-width]
    {
        -webkit-transition: opacity .5s ease;
        -o-transition: opacity .5s ease;
        transition: opacity .5s ease;
        overflow: hidden
    }

    .vc_section[data-vc-full-width].vc_hidden
    {
        opacity: 0
    }

    .vc_section[data-vc-full-width] > .vc_row
    {
        margin-left: 0;
        margin-right: 0
    }

    .vc_section[data-vc-stretch-content]
    {
        padding-left: 0;
        padding-right: 0
    }

    .vc_section.vc_row-o-full-height
    {
        min-height: 100vh
    }

    .vc_section.vc_section-flex
    {
        box-sizing: border-box;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap
    }

    .vc_section.vc_section-flex .vc_vc_row
    {
        width: 100%
    }

    .vc_section.vc_section-flex::after,
    .vc_section.vc_section-flex::before
    {
        display: none
    }

    .vc_section.vc_section-o-content-top
    {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .vc_section.vc_section-o-content-bottom
    {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .vc_section.vc_section-o-content-middle
    {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .vc_section.vc_section-o-content-bottom::after,
    .vc_section.vc_section-o-content-middle::after,
    .vc_section.vc_section-o-content-top::after
    {
        content: '';
        width: 100%;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        display: block
    }

    .vc_section.vc_section-has-fill,
    .vc_section.vc_section-has-fill + .vc_row-full-width + .vc_section,
    .vc_section.vc_section-has-fill + .vc_section
    {
        padding-top: 35px
    }


    .vc_row.vc_column-gap-20 > .vc_vc_column > .vc_column_container,
    .vc_row.vc_column-gap-20 > .vc_vc_column_inner > .vc_column_container
    {
        padding-left: 10px;
        padding-right: 10px
    }

    .vc_vc_column,
    .vc_vc_column_inner
    {
        padding-left: 0;
        padding-right: 0
    }

    .vc_row.vc_row-flex > .vc_vc_column,
    .vc_row.vc_row-flex > .vc_vc_column_inner
    {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }

    .vc_row.vc_row-flex > .vc_vc_column > .vc_column_container,
    .vc_row.vc_row-flex > .vc_vc_column_inner > .vc_column_container
    {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%
    }

    .vc_row.vc_row-flex > .vc_vc_column > .vc_column_container > .vc_column-inner,
    .vc_row.vc_row-flex > .vc_vc_column_inner > .vc_column_container > .vc_column-inner
    {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .vc_row.vc_row-o-equal-height > .vc_column_container
    {
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .vc_row.vc_row-o-content-top > .vc_vc_column > .vc_column_container > .vc_column-inner,
    .vc_row.vc_row-o-content-top > .vc_vc_column_inner > .vc_column_container > .vc_column-inner
    {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .vc_row.vc_row-o-content-top:not(.vc_row-o-equal-height) > .vc_vc_column > .vc_column_container,
    .vc_row.vc_row-o-content-top:not(.vc_row-o-equal-height) > .vc_vc_column_inner > .vc_column_container
    {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .vc_row.vc_row-o-content-bottom > .vc_vc_column > .vc_column_container > .vc_column-inner,
    .vc_row.vc_row-o-content-bottom > .vc_vc_column_inner > .vc_column_container > .vc_column-inner
    {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .vc_row.vc_row-o-content-bottom:not(.vc_row-o-equal-height) > .vc_vc_column > .vc_column_container,
    .vc_row.vc_row-o-content-bottom:not(.vc_row-o-equal-height) > .vc_vc_column_inner > .vc_column_container
    {
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .vc_row.vc_row-o-content-middle > .vc_vc_column > .vc_column_container > .vc_column-inner,
    .vc_row.vc_row-o-content-middle > .vc_vc_column_inner > .vc_column_container > .vc_column-inner
    {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .vc_row.vc_row-o-content-middle:not(.vc_row-o-equal-height) > .vc_vc_column > .vc_column_container,
    .vc_row.vc_row-o-content-middle:not(.vc_row-o-equal-height) > .vc_vc_column_inner > .vc_column_container
    {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .vc_empty-element > .vc_column-inner
    {
        min-height: 100px
    }

    .vc_row-has-fill + .vc_vc_row > .vc_row > .vc_vc_column > .vc_column_container > .vc_column-inner,
    .vc_row-has-fill + .vc_vc_row_inner > .vc_row > .vc_vc_column_inner > .vc_column_container > .vc_column-inner,
    .vc_row-has-fill > .vc_row > .vc_vc_column > .vc_column_container > .vc_column-inner,
    .vc_row-has-fill > .vc_vc_column_inner > .vc_column_container > .vc_column-inner
    {
        padding-top: 35px
    }

    .vc_grid.vc_row
    {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0
    }

    .vc-row[data-vc-full-width] .vc_grid.vc_row
    {
        overflow: hidden
    }

    .vc_grid.vc_row.vc_grid-gutter-1px
    {
        margin-bottom: -1px
    }

    .vc_grid.vc_row.vc_grid-gutter-1px .vc_pageable-slide-wrapper
    {
        margin-right: -1px
    }

    .vc_grid.vc_row.vc_grid-gutter-1px .vc_grid-item
    {
        padding-right: 1px;
        padding-bottom: 1px
    }

    .vc_grid.vc_row.vc_grid-gutter-2px
    {
        margin-bottom: -2px
    }

    .vc_grid.vc_row.vc_grid-gutter-2px .vc_pageable-slide-wrapper
    {
        margin-right: -2px
    }

    .vc_grid.vc_row.vc_grid-gutter-2px .vc_grid-item
    {
        padding-right: 2px;
        padding-bottom: 2px
    }

    .vc_grid.vc_row.vc_grid-gutter-3px
    {
        margin-bottom: -3px
    }

    .vc_grid.vc_row.vc_grid-gutter-3px .vc_pageable-slide-wrapper
    {
        margin-right: -3px
    }

    .vc_grid.vc_row.vc_grid-gutter-3px .vc_grid-item
    {
        padding-right: 3px;
        padding-bottom: 3px
    }

    .vc_grid.vc_row.vc_grid-gutter-4px
    {
        margin-bottom: -4px
    }

    .vc_grid.vc_row.vc_grid-gutter-4px .vc_pageable-slide-wrapper
    {
        margin-right: -4px
    }

    .vc_grid.vc_row.vc_grid-gutter-4px .vc_grid-item
    {
        padding-right: 4px;
        padding-bottom: 4px
    }

    .vc_grid.vc_row.vc_grid-gutter-5px
    {
        margin-bottom: -5px
    }

    .vc_grid.vc_row.vc_grid-gutter-5px .vc_pageable-slide-wrapper
    {
        margin-right: -5px
    }

    .vc_grid.vc_row.vc_grid-gutter-5px .vc_grid-item
    {
        padding-right: 5px;
        padding-bottom: 5px
    }

    .vc_grid.vc_row.vc_grid-gutter-10px
    {
        margin-bottom: -10px
    }

    .vc_grid.vc_row.vc_grid-gutter-10px .vc_pageable-slide-wrapper
    {
        margin-right: -10px
    }

    .vc_grid.vc_row.vc_grid-gutter-10px .vc_grid-item
    {
        padding-right: 10px;
        padding-bottom: 10px
    }

    .vc_grid.vc_row.vc_grid-gutter-15px
    {
        margin-bottom: -15px
    }

    .vc_grid.vc_row.vc_grid-gutter-15px .vc_pageable-slide-wrapper
    {
        margin-right: -15px
    }

    .vc_grid.vc_row.vc_grid-gutter-15px .vc_grid-item
    {
        padding-right: 15px;
        padding-bottom: 15px
    }

    .vc_grid.vc_row.vc_grid-gutter-20px
    {
        margin-bottom: -20px
    }

    .vc_grid.vc_row.vc_grid-gutter-20px .vc_pageable-slide-wrapper
    {
        margin-right: -20px
    }

    .vc_grid.vc_row.vc_grid-gutter-25px
    {
        margin-bottom: -25px
    }

    .vc_grid.vc_row.vc_grid-gutter-25px .vc_pageable-slide-wrapper
    {
        margin-right: -25px
    }

    .vc_grid.vc_row.vc_grid-gutter-25px .vc_grid-item
    {
        padding-right: 25px;
        padding-bottom: 25px
    }

    .vc_grid.vc_row.vc_grid-gutter-30px
    {
        margin-bottom: -30px
    }

    .vc_grid.vc_row.vc_grid-gutter-30px .vc_pageable-slide-wrapper
    {
        margin-right: -30px
    }

    .vc_grid.vc_row.vc_grid-gutter-30px .vc_grid-item
    {
        padding-right: 30px;
        padding-bottom: 30px
    }

    .vc_grid.vc_row.vc_grid-gutter-35px
    {
        margin-bottom: -35px
    }

    .vc_grid.vc_row.vc_grid-gutter-35px .vc_pageable-slide-wrapper
    {
        margin-right: -35px
    }

    .vc_grid.vc_row.vc_grid-gutter-35px .vc_grid-item
    {
        padding-right: 35px;
        padding-bottom: 35px
    }

    .vc_grid.vc_row .vc_pageable-slide-wrapper .vc_grid-item.vc_visible-item:hover
    {
        z-index: 3
    }

    .vc_grid.vc_row .vc_grid-item .vc_grid-item-mini .vc_grid-item-content
    {
        overflow: hidden
    }

    .vc_grid.vc_row .vc_grid-item .vc_btn,
    .vc_grid.vc_row .vc_grid-item .vc_icon_element,
    .vc_grid.vc_row .vc_grid-item .wpb_content_element
    {
        margin-bottom: 15px
    }

    .vc_grid.vc_row .vc_grid-item .vc_btn a,
    .vc_grid.vc_row .vc_grid-item .vc_icon_element a,
    .vc_grid.vc_row .vc_grid-item .wpb_content_element a
    {
        text-decoration: none;
        border-bottom: 0
    }

    .vc_grid .vc_gitem-link
    {
        border-bottom: none;
        outline: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }

    .vc_masonry_grid .vc_grid.vc_row .vc_grid-item,
    .vc_masonry_media_grid .vc_grid.vc_row .vc_grid-item
    {
        position: static
    }

    .vc_basic_grid .vc_grid.vc_row .vc_grid-item.vc_visible-item,
    .vc_media_grid .vc_grid.vc_row .vc_grid-item.vc_visible-item
    {
        display: block
    }

    @media (min-width: 768px)
    {
        .vc_basic_grid .vc_grid.vc_row .vc_grid-item.vc_visible-item,
        .vc_media_grid .vc_grid.vc_row .vc_grid-item.vc_visible-item
        {
            display: inline-block
        }
    }

    @-moz-document url-prefix()
    {
        .vc_masonry_grid .vc_grid-item,
        .vc_masonry_media_grid .vc_grid-item
        {
            margin-left: -.01px
        }
    }

    .vc_gitem-zone-b
    {
        display: none
    }

    .vc_gitem-float-none
    {
        float: none
    }

    .vc_gitem-float-left
    {
        float: left
    }

    .vc_gitem-float-right
    {
        float: right
    }

    .vc_gitem-align-center
    {
        text-align: center
    }

    .vc_gitem-align-right
    {
        text-align: right
    }

    .vc_gitem-align-justify
    {
        text-align: justify
    }

    .vc_gitem-block
    {
        position: relative;
        overflow: hidden
    }

    .vc_gitem-is-link
    {
        cursor: pointer
    }

    .vc_gitem-link
    {
        text-decoration: none
    }

    .vc_gitem_row .vc_gitem_row
    {
        margin-bottom: 0
    }

    .vc_gitem-zone-a .vc_gitem-row-position-top,
    .vc_gitem-zone-b .vc_gitem-row-position-top
    {
        position: absolute;
        left: 0;
        right: 0;
        top: 0
    }

    .vc_gitem-zone-a .vc_gitem-row-position-middle,
    .vc_gitem-zone-b .vc_gitem-row-position-middle
    {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%)
    }

    .vc_gitem-zone-a .vc_gitem-row-position-bottom,
    .vc_gitem-zone-b .vc_gitem-row-position-bottom
    {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0
    }

    .vc_gitem_row .vc_gitem-col
    {
        box-sizing: border-box;
        padding: 10px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover
    }

    .vc_gitem-zone
    {
        position: relative;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        background-clip: border-box;
        box-sizing: border-box;
        overflow: hidden
    }

    .vc_gitem-zone .vc-zone-link
    {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 5
    }

    .vc_gitem-zone-img
    {
        width: 100%;
        display: block;
        height: auto
    }

    .vc_gitem-zone-a .vc_gitem-zone-mini
    {
        box-sizing: border-box;
        padding: inherit;
        position: static;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0
    }

    .vc_gitem-zone-b
    {
        overflow: hidden
    }

    .vc_gitem-zone-b .vc_gitem-zone-mini
    {
        position: static;
        padding: inherit;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0
    }

    .vc_grid-item-zone-c-left .vc_gitem-animated-block
    {
        float: right;
        width: 50%
    }

    .vc_grid-item-zone-c-left .vc_gitem-zone-c
    {
        float: left;
        width: 50%
    }

    .vc_grid-item-zone-c-right .vc_gitem-animated-block
    {
        float: left;
        width: 50%
    }

    .vc_grid-item-zone-c-right .vc_gitem-zone-c
    {
        float: right;
        width: 50%
    }

    .vc-gitem-zone-height-mode-auto .vc_gitem-zone-img
    {
        display: none !important
    }

    .vc-gitem-zone-height-mode-auto:before
    {
        content: "";
        display: block;
        padding-top: 100%
    }

    .vc-gitem-zone-height-mode-auto.vc-gitem-zone-height-mode-auto-4-3:before
    {
        padding-top: 75%
    }

    .vc-gitem-zone-height-mode-auto.vc-gitem-zone-height-mode-auto-3-4:before
    {
        padding-top: 133.33333333%
    }

    .vc-gitem-zone-height-mode-auto.vc-gitem-zone-height-mode-auto-3-4:before
    {
        padding-top: 133.33333333%
    }

    .vc-gitem-zone-height-mode-auto.vc-gitem-zone-height-mode-auto-16-9:before
    {
        padding-top: 56.25%
    }

    .vc-gitem-zone-height-mode-auto.vc-gitem-zone-height-mode-auto-9-16:before
    {
        padding-top: 177.77777778%
    }

    .vc_gitem-animated-block
    {
        position: relative
    }

    .vc_gitem-animate .vc_btn
    {
        -webkit-transition: all 0s ease;
        -o-transition: all 0s ease;
        transition: all 0s ease
    }

    .vc_gitem-animate .vc_gitem-zone-b
    {
        z-index: 199
    }

    .vc_gitem-animate-none .vc_gitem-zone-b
    {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        box-sizing: border-box
    }

    .vc_grid-item-mini.vc_is-hover .vc_gitem-animate-none .vc_gitem-zone-b
    {
        opacity: 1
    }

    .vc_gitem-animate-fadeIn .vc_gitem-zone-b
    {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        box-sizing: border-box
    }

    .vc_gitem-animate-fadeIn .vc_gitem-zone-b .vc_separator
    {
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        width: 0
    }

    .vc_gitem-animate-fadeIn .vc_gitem-zone-b .vc_btn
    {
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0)
    }

    .vc_gitem-animate-fadeIn .vc_gitem-zone-b .vc_post-title
    {
        display: inline-block;
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
        -webkit-transition: -webkit-transform 1s cubic-bezier(0, .275, .125, 1), opacity .5s cubic-bezier(0, .275, .125, 1);
        -moz-transition: -moz-transform 1s cubic-bezier(0, .275, .125, 1), opacity .5s cubic-bezier(0, .275, .125, 1);
        transition: transform 1s cubic-bezier(0, .275, .125, 1), opacity .5s cubic-bezier(0, .275, .125, 1)
    }

    .vc_grid-item-mini.vc_is-hover .vc_gitem-animate-fadeIn .vc_gitem-zone-b .vc_post-title
    {
        opacity: 1
    }

    .vc_grid-item-mini.vc_is-hover .vc_gitem-animate-fadeIn .vc_gitem-zone-b .vc_post-title .vc_separator
    {
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        width: 50%
    }

    .vc_grid-item-mini.vc_is-hover .vc_gitem-animate-fadeIn .vc_gitem-zone-b .vc_post-title .vc_btn
    {
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    .vc_grid-item-mini.vc_is-hover .vc_gitem-animate-fadeIn .vc_gitem-zone-b .vc_post-title .vc_post-title
    {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    .vc_grid-item-mini.vc_is-hover .vc_gitem-animate-fadeIn .vc_gitem-zone-b
    {
        opacity: 1
    }

    .vc_grid-item-mini.vc_is-hover .vc_gitem-animate-fadeIn .vc_gitem-zone-b .vc_separator
    {
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        width: 50%
    }

    .vc_grid-item-mini.vc_is-hover .vc_gitem-animate-fadeIn .vc_gitem-zone-b .vc_btn
    {
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    .vc_grid-item-mini.vc_is-hover .vc_gitem-animate-fadeIn .vc_gitem-zone-b .vc_post-title
    {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }


    .vc_grid-item-mini.vc_is-hover .vc_gitem-animate-slideTop .vc_gitem-zone-b
    {
        top: 0
    }


    .vc_gitem-animate-goTop20
    {
        overflow: hidden
    }


    .vc_gitem-post-data
    {
        margin-bottom: 15px
    }

    .vc_gitem-post-data h2
    {
        margin: 0
    }

    .vc_gitem-post-category-name .vc_gitem-link
    {
        color: inherit
    }

    .vc_grid-loading
    {
        margin: 2em auto;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        -webkit-animation-delay: .16s;
        animation-delay: .16s;
        height: 20px
    }

    .vc_grid-loading,
    .vc_grid-loading:after,
    .vc_grid-loading:before
    {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out
    }

    .vc_grid-loading:before
    {
        left: -20px
    }

    .vc_grid-loading:after
    {
        left: 20px;
        -webkit-animation-delay: .32s;
        animation-delay: .32s
    }

    .vc_grid-loading:after,
    .vc_grid-loading:before
    {
        content: '';
        position: absolute;
        top: 0
    }

    @-webkit-keyframes load7
    {
        0%,
        100%,
        80%
        {
            box-shadow: 0 2.5em 0 -1.3em rgba(235, 235, 235, .75)
        }
        40%
        {
            box-shadow: 0 2.5em 0 0 rgba(235, 235, 235, .75)
        }
    }

    @keyframes load7
    {
        0%,
        100%,
        80%
        {
            box-shadow: 0 2.5em 0 -1.3em rgba(235, 235, 235, .75)
        }
        40%
        {
            box-shadow: 0 2.5em 0 0 rgba(235, 235, 235, .75)
        }
    }

    .vc_grid-filter
    {
        margin-left: 0;
        margin-bottom: 20px;
        list-style: none;
        padding: 0
    }

    .vc_grid-filter.vc_grid-filter-center
    {
        text-align: center
    }

    .vc_grid-filter.vc_grid-filter-right
    {
        text-align: right
    }

    .vc_grid-filter.vc_grid-filter-left
    {
        text-align: left
    }

    .vc_grid-filter.vc_grid-filter-color-blue > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-blue > .vc_grid-filter-item:hover
    {
        background-color: #5472d2
    }

    .vc_grid-filter.vc_grid-filter-color-blue > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-blue > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-turquoise > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-turquoise > .vc_grid-filter-item:hover
    {
        background-color: #00c1cf
    }

    .vc_grid-filter.vc_grid-filter-color-turquoise > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-turquoise > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-pink > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-pink > .vc_grid-filter-item:hover
    {
        background-color: #fe6c61
    }

    .vc_grid-filter.vc_grid-filter-color-pink > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-pink > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-violet > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-violet > .vc_grid-filter-item:hover
    {
        background-color: #8d6dc4
    }

    .vc_grid-filter.vc_grid-filter-color-violet > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-violet > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-peacoc > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-peacoc > .vc_grid-filter-item:hover
    {
        background-color: #4cadc9
    }

    .vc_grid-filter.vc_grid-filter-color-peacoc > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-peacoc > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-chino > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-chino > .vc_grid-filter-item:hover
    {
        background-color: #cec2ab
    }

    .vc_grid-filter.vc_grid-filter-color-chino > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-chino > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item:hover
    {
        background-color: #50485b
    }

    .vc_grid-filter.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-vista_blue > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-vista_blue > .vc_grid-filter-item:hover
    {
        background-color: #75d69c
    }

    .vc_grid-filter.vc_grid-filter-color-vista_blue > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-vista_blue > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-black > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-black > .vc_grid-filter-item:hover
    {
        background-color: #2a2a2a
    }

    .vc_grid-filter.vc_grid-filter-color-black > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-black > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-grey > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-grey > .vc_grid-filter-item:hover
    {
        background-color: #ebebeb
    }

    .vc_grid-filter.vc_grid-filter-color-grey > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-grey > .vc_grid-filter-item:hover > span
    {
        color: #666666
    }

    .vc_grid-filter.vc_grid-filter-color-orange > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-orange > .vc_grid-filter-item:hover
    {
        background-color: #f7be68
    }

    .vc_grid-filter.vc_grid-filter-color-orange > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-orange > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-sky > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-sky > .vc_grid-filter-item:hover
    {
        background-color: #5aa1e3
    }

    .vc_grid-filter.vc_grid-filter-color-sky > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-sky > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-green > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-green > .vc_grid-filter-item:hover
    {
        background-color: #6dab3c
    }

    .vc_grid-filter.vc_grid-filter-color-green > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-green > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item:hover
    {
        background-color: #f4524d
    }

    .vc_grid-filter.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item:hover
    {
        background-color: #f79468
    }

    .vc_grid-filter.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-purple > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-purple > .vc_grid-filter-item:hover
    {
        background-color: #b97ebb
    }

    .vc_grid-filter.vc_grid-filter-color-purple > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-purple > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-white > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-color-white > .vc_grid-filter-item:hover
    {
        background-color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-color-white > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-color-white > .vc_grid-filter-item:hover > span
    {
        color: #666666
    }

    .vc_grid-filter > .vc_grid-filter-item
    {
        cursor: pointer;
        display: inline-block;
        background: 0 0;
        padding: 4px 10px;
        -webkit-transition: background-color .1s linear;
        -o-transition: background-color .1s linear;
        transition: background-color .1s linear
    }

    .vc_grid-filter > .vc_grid-filter-item:first-child
    {
        margin-left: 0 !important
    }

    .vc_grid-filter > .vc_grid-filter-item > span
    {
        -webkit-transition: color .1s linear;
        -o-transition: color .1s linear;
        transition: color .1s linear;
        outline: 0;
        padding: 0;
        text-decoration: none
    }

    .vc_grid-filter > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter > .vc_grid-filter-item:hover > span
    {
        text-decoration: none
    }

    .vc_grid-filter.vc_grid-filter-default > .vc_grid-filter-item
    {
        margin: 0 5px 5px 0;
        border-radius: 30px
    }

    .vc_grid-filter.vc_grid-filter- .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-comma .vc_grid-filter-item
    {
        padding: 0 !important
    }

    .vc_grid-filter.vc_grid-filter-default-less-rounded > .vc_grid-filter-item
    {
        margin: 0 5px 5px 0;
        border-radius: 5px
    }

    .vc_grid-filter.vc_grid-filter-bordered-rounded-less > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-bordered-rounded > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-bordered > .vc_grid-filter-item
    {
        margin: 0;
        border: 1px solid rgba(235, 235, 235, .8);
        border-collapse: collapse
    }

    .vc_grid-filter.vc_grid-filter-bordered-rounded-less > .vc_grid-filter-item:nth-child(n+2),
    .vc_grid-filter.vc_grid-filter-bordered-rounded > .vc_grid-filter-item:nth-child(n+2),
    .vc_grid-filter.vc_grid-filter-bordered > .vc_grid-filter-item:nth-child(n+2)
    {
        margin-left: -1px;
        margin-top: -1px
    }

    .vc_grid-filter.vc_grid-filter-bordered-rounded-less > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-bordered-rounded-less > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-bordered-rounded > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-bordered-rounded > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-bordered > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-bordered > .vc_grid-filter-item:hover
    {
        position: relative;
        z-index: 1;
        border-color: transparent
    }

    .vc_grid-filter.vc_grid-filter-bordered-rounded-less > .vc_grid-filter-item.vc_active > a,
    .vc_grid-filter.vc_grid-filter-bordered-rounded-less > .vc_grid-filter-item:hover > a,
    .vc_grid-filter.vc_grid-filter-bordered-rounded > .vc_grid-filter-item.vc_active > a,
    .vc_grid-filter.vc_grid-filter-bordered-rounded > .vc_grid-filter-item:hover > a,
    .vc_grid-filter.vc_grid-filter-bordered > .vc_grid-filter-item.vc_active > a,
    .vc_grid-filter.vc_grid-filter-bordered > .vc_grid-filter-item:hover > a
    {
        text-decoration: none
    }

    .vc_grid-filter.vc_grid-filter-bordered-rounded > .vc_grid-filter-item:first-child
    {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px
    }

    .vc_grid-filter.vc_grid-filter-bordered-rounded > .vc_grid-filter-item:last-child
    {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px
    }

    .vc_grid-filter.vc_grid-filter-bordered-rounded-less > .vc_grid-filter-item:first-child
    {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px
    }

    .vc_grid-filter.vc_grid-filter-bordered-rounded-less > .vc_grid-filter-item:last-child
    {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-blue > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-blue > .vc_grid-filter-item
    {
        background-color: #7c93dd
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-blue > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-blue > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-blue > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-blue > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-blue > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-blue > .vc_grid-filter-item:hover
    {
        background-color: #5472d2
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-blue > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-blue > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-blue > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-blue > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-turquoise > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-turquoise > .vc_grid-filter-item
    {
        background-color: #00919c
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-turquoise > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-turquoise > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-turquoise > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-turquoise > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-turquoise > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-turquoise > .vc_grid-filter-item:hover
    {
        background-color: #00c1cf
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-turquoise > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-turquoise > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-turquoise > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-turquoise > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-pink > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-pink > .vc_grid-filter-item
    {
        background-color: #fe9b94
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-pink > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-pink > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-pink > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-pink > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-pink > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-pink > .vc_grid-filter-item:hover
    {
        background-color: #fe6c61
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-pink > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-pink > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-pink > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-pink > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-violet > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-violet > .vc_grid-filter-item
    {
        background-color: #a991d3
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-violet > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-violet > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-violet > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-violet > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-violet > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-violet > .vc_grid-filter-item:hover
    {
        background-color: #8d6dc4
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-violet > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-violet > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-violet > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-violet > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-peacoc > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-peacoc > .vc_grid-filter-item
    {
        background-color: #73bfd5
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-peacoc > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-peacoc > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-peacoc > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-peacoc > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-peacoc > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-peacoc > .vc_grid-filter-item:hover
    {
        background-color: #4cadc9
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-peacoc > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-peacoc > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-peacoc > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-peacoc > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-chino > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-chino > .vc_grid-filter-item
    {
        background-color: #e1d9cb
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-chino > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-chino > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-chino > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-chino > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-chino > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-chino > .vc_grid-filter-item:hover
    {
        background-color: #cec2ab
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-chino > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-chino > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-chino > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-chino > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item
    {
        background-color: #695f77
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item:hover
    {
        background-color: #50485b
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-mulled_wine > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-vista_blue > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-vista_blue > .vc_grid-filter-item
    {
        background-color: #9ce2b8
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-vista_blue > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-vista_blue > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-vista_blue > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-vista_blue > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-vista_blue > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-vista_blue > .vc_grid-filter-item:hover
    {
        background-color: #75d69c
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-vista_blue > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-vista_blue > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-vista_blue > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-vista_blue > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-black > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-black > .vc_grid-filter-item
    {
        background-color: #444444
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-black > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-black > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-black > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-black > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-black > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-black > .vc_grid-filter-item:hover
    {
        background-color: #2a2a2a
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-black > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-black > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-black > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-black > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-grey > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-grey > .vc_grid-filter-item
    {
        background-color: #d2d2d2
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-grey > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-grey > .vc_grid-filter-item > span
    {
        color: #666666
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-grey > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-grey > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-grey > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-grey > .vc_grid-filter-item:hover
    {
        background-color: #ebebeb
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-grey > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-grey > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-grey > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-grey > .vc_grid-filter-item:hover > span
    {
        color: #666666
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-orange > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-orange > .vc_grid-filter-item
    {
        background-color: #fad398
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-orange > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-orange > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-orange > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-orange > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-orange > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-orange > .vc_grid-filter-item:hover
    {
        background-color: #f7be68
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-orange > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-orange > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-orange > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-orange > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sky > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sky > .vc_grid-filter-item
    {
        background-color: #86baea
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sky > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sky > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sky > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sky > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sky > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sky > .vc_grid-filter-item:hover
    {
        background-color: #5aa1e3
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sky > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sky > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sky > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sky > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-green > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-green > .vc_grid-filter-item
    {
        background-color: #87c456
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-green > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-green > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-green > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-green > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-green > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-green > .vc_grid-filter-item:hover
    {
        background-color: #6dab3c
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-green > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-green > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-green > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-green > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item
    {
        background-color: #f7817d
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item:hover
    {
        background-color: #f4524d
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-juicy_pink > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item
    {
        background-color: #fab698
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item:hover
    {
        background-color: #f79468
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-sandy_brown > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-purple > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-purple > .vc_grid-filter-item
    {
        background-color: #cb9fcd
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-purple > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-purple > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-purple > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-purple > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-purple > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-purple > .vc_grid-filter-item:hover
    {
        background-color: #b97ebb
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-purple > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-purple > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-purple > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-purple > .vc_grid-filter-item:hover > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-white > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-white > .vc_grid-filter-item
    {
        background-color: #e6e6e6
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-white > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-white > .vc_grid-filter-item > span
    {
        color: #666666
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-white > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-white > .vc_grid-filter-item:hover,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-white > .vc_grid-filter-item.vc_active,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-white > .vc_grid-filter-item:hover
    {
        background-color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-white > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled-rounded.vc_grid-filter-color-white > .vc_grid-filter-item:hover > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-white > .vc_grid-filter-item.vc_active > span,
    .vc_grid-filter.vc_grid-filter-filled.vc_grid-filter-color-white > .vc_grid-filter-item:hover > span
    {
        color: #666666
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded > .vc_grid-filter-item,
    .vc_grid-filter.vc_grid-filter-filled > .vc_grid-filter-item
    {
        margin: 0;
        border: 1px solid transparent;
        border-collapse: collapse;
        padding: 3px 7px
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded > .vc_grid-filter-item > span,
    .vc_grid-filter.vc_grid-filter-filled > .vc_grid-filter-item > span
    {
        color: #ffffff
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded > .vc_grid-filter-item:nth-child(n+2),
    .vc_grid-filter.vc_grid-filter-filled > .vc_grid-filter-item:nth-child(n+2)
    {
        border-left: none;
        margin-left: 1px;
        border-top: none;
        margin-top: 1px
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded > .vc_grid-filter-item:first-child,
    .vc_grid-filter.vc_grid-filter-filled > .vc_grid-filter-item:first-child
    {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top: 0
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded > .vc_grid-filter-item:last-child,
    .vc_grid-filter.vc_grid-filter-filled > .vc_grid-filter-item:last-child
    {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded > .vc_grid-filter-item:first-child
    {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top: 0
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded > .vc_grid-filter-item:last-child
    {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px
    }

    .vc_grid-filter.vc_grid-filter-filled-rounded-all > .vc_grid-filter-item
    {
        border-radius: 3px !important
    }

    .vc_grid-filter.vc_grid-filter-filled-round-all > .vc_grid-filter-item
    {
        border-radius: 30px !important
    }

    .vc_grid-filter.vc_grid-filter-size-xs .vc_grid-filter-item
    {
        font-size: 12px;
        padding: 1px 5px
    }

    .vc_grid-filter.vc_grid-filter-size-sm .vc_grid-filter-item
    {
        font-size: 13px;
        padding: 2px 7px
    }

    .vc_grid-filter.vc_grid-filter-size-md .vc_grid-filter-item
    {
        font-size: 100%;
        padding: 2px 10px
    }

    .vc_grid-filter.vc_grid-filter-size-lg .vc_grid-filter-item
    {
        font-size: 18px;
        padding: 4px 10px
    }

    .vc_grid-filter-select
    {
        display: none
    }

    .vc_grid-filter-dropdown,
    .vc_grid-filter-select
    {
        font-size: 12px;
        margin-bottom: 20px
    }

    .vc_grid-filter-dropdown.vc_grid-filter-center,
    .vc_grid-filter-select.vc_grid-filter-center
    {
        text-align: center
    }

    .vc_grid-filter-dropdown.vc_grid-filter-right,
    .vc_grid-filter-select.vc_grid-filter-right
    {
        text-align: right
    }

    .vc_grid-filter-dropdown.vc_grid-filter-left,
    .vc_grid-filter-select.vc_grid-filter-left
    {
        text-align: left
    }

    .vc_grid-filter-dropdown .vc_grid-styled-select .vc_arrow-icon-navicon,
    .vc_grid-filter-select .vc_grid-styled-select .vc_arrow-icon-navicon
    {
        display: none
    }

    .vc_grid-filter-dropdown .vc_grid-styled-select select,
    .vc_grid-filter-select .vc_grid-styled-select select
    {
        outline: 0;
        display: inline-block
    }

    @media (min-width: 768px)
    {
        .vc_grid-filter-dropdown.vc_grid-filter-color-blue .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-blue .vc_arrow-icon-navicon
        {
            color: #5472d2
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-turquoise .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-turquoise .vc_arrow-icon-navicon
        {
            color: #00c1cf
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-pink .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-pink .vc_arrow-icon-navicon
        {
            color: #fe6c61
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-violet .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-violet .vc_arrow-icon-navicon
        {
            color: #8d6dc4
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-peacoc .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-peacoc .vc_arrow-icon-navicon
        {
            color: #4cadc9
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-chino .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-chino .vc_arrow-icon-navicon
        {
            color: #cec2ab
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-mulled_wine .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-mulled_wine .vc_arrow-icon-navicon
        {
            color: #50485b
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-vista_blue .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-vista_blue .vc_arrow-icon-navicon
        {
            color: #75d69c
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-black .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-black .vc_arrow-icon-navicon
        {
            color: #2a2a2a
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-grey .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-grey .vc_arrow-icon-navicon
        {
            color: #ebebeb
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-orange .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-orange .vc_arrow-icon-navicon
        {
            color: #f7be68
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-sky .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-sky .vc_arrow-icon-navicon
        {
            color: #5aa1e3
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-green .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-green .vc_arrow-icon-navicon
        {
            color: #6dab3c
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-juicy_pink .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-juicy_pink .vc_arrow-icon-navicon
        {
            color: #f4524d
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-sandy_brown .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-sandy_brown .vc_arrow-icon-navicon
        {
            color: #f79468
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-purple .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-purple .vc_arrow-icon-navicon
        {
            color: #b97ebb
        }
        .vc_grid-filter-dropdown.vc_grid-filter-color-white .vc_arrow-icon-navicon,
        .vc_grid-filter-select.vc_grid-filter-color-white .vc_arrow-icon-navicon
        {
            color: #ffffff
        }
        .vc_grid-filter-dropdown .vc_grid-styled-select,
        .vc_grid-filter-select .vc_grid-styled-select
        {
            border: 1px solid #cccccc;
            display: inline-block;
            height: 30px;
            overflow: hidden;
            padding-right: 5px;
            text-align: left;
            width: auto;
            vertical-align: middle
        }
        .vc_grid-filter-dropdown .vc_grid-styled-select .vc_arrow-icon-navicon,
        .vc_grid-filter-select .vc_grid-styled-select .vc_arrow-icon-navicon
        {
            font-size: 17px;
            display: inline;
            vertical-align: middle
        }
        .vc_grid-filter-dropdown .vc_grid-styled-select select,
        .vc_grid-filter-select .vc_grid-styled-select select
        {
            background: none repeat scroll 0 0 transparent;
            border: 0 none;
            border-radius: 0;
            height: 30px;
            line-height: 1;
            margin-right: -50px;
            padding: 5px;
            position: relative;
            font-size: 12px;
            z-index: 0;
            cursor: pointer;
            max-width: inherit !important
        }
    }

    @media (max-width: 768px)
    {
        .vc_responsive .vc_grid-filter-select
        {
            display: block
        }
        .vc_responsive .vc_grid-filter
        {
            display: none !important
        }
    }
}
